import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useReducer,
  useState,
} from 'react'
import validator from 'validator'

import { Box, Paper, Popper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { LocationType } from '../../../../api/types'

import {
  setIsCompanyPanel,
  setIsMembersPanel,
} from '../../../../store/Builder/actions'
import {
  getCompaniesByNameRequest,
  getCompaniesRequestSuccess,
  saveCompanyRequest,
  updateCompanyRequest,
} from '../../../../store/Companies/actions'

import {
  selectCompanyType,
  selectIsMembersPanel,
} from '../../../../store/Builder/selectors'
import {
  selectCompanies,
  selectCurrentCompany,
  selectGetCompaniesRequestStatus,
  selectSaveCompanyRequestStatus,
} from '../../../../store/Companies/selectors'
import { Company } from '../../../../store/Companies/types'
import { removeEmptyFields } from '../../../../utils/filters'
import CustomTextField from '../../../../components/CustomTextField'
import CompanyOption from './CompanyOption'
import {
  setAddressVisible,
  setCompanyAddress,
  setCompanyLogo,
  setCompanyName,
  setCompanyWebsite,
  setSaveInCatalog,
  setWebsiteVisible,
} from './localStore/actions'
import { companyFormReducer } from './localStore/reducer'
import SearchField from '../../../../components/SearchField'
import SidePanel from '../../../../components/SidePanel'
import ControlButtons from '../../../../components/ControlButtons'
import MembersForm from '../MembersForm'
import ImageUploadField from './ImageUploadField'
import TextHead from '../../../../components/HeadText'
import LocationAutocomplete from '../../../../components/LocationAutocomplete'

import { LinkIcon, MapPinIcon } from '../../../../assets/icons'
import MemberField from './MemberField'
import SaveToCatalogField from './SaveToCatalogField'
import { ErrorsFieldsTypes } from './types'

import { DEFAULT_ERRORS_STATE } from './constants'
import style from './style'

const CompanyForm = () => {
  const companyType = useSelector(selectCompanyType)
  const companies = useSelector(selectCompanies)
  const company = useSelector(selectCurrentCompany)
  const isMembersPanel = useSelector(selectIsMembersPanel)
  const saveRequestStatus = useSelector(selectSaveCompanyRequestStatus)
  const getRequestStatus = useSelector(selectGetCompaniesRequestStatus)

  const [
    {
      id,
      name,
      logo,
      address,
      website,
      addressVisible,
      websiteVisible,
      saveInCatalog,
      primaryMembers,
    },
    companyDispatch,
  ] = useReducer(companyFormReducer, {
    id: company.id || undefined,
    name: company.name || '',
    logo: company.logo || '',
    website: company.website || '',
    address: company.address || '',
    websiteVisible: company.websiteVisible || false,
    addressVisible: company.addressVisible || false,
    saveInCatalog: company.saveInCatalog || true,
    primaryMembers: company.primaryMembers || [],
  })

  const [errors, setErrors] = useState(DEFAULT_ERRORS_STATE)

  const dispatch = useDispatch()

  const clearErrorField = (field: keyof ErrorsFieldsTypes) => {
    if (errors[field]) {
      setErrors({ ...errors, [field]: false })
    }
  }

  const handleSetName = (value: string) => {
    if (errors.name) {
      setErrors({ ...errors, name: false })
    }

    clearErrorField('name')
    companyDispatch(setCompanyName(value))
  }

  const handleCompanySearch: ChangeEventHandler<HTMLTextAreaElement> = async ({
    target: { value },
  }) => {
    handleSetName(value)
    if (value.length > 2) {
      dispatch(getCompaniesByNameRequest(value))
    }
  }

  const handleMembersPanel = () => {
    dispatch(setIsMembersPanel(false))
  }

  const handleSetLogo = (value: string) => {
    companyDispatch(setCompanyLogo(value || ''))
  }

  const handleSetWebsite: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    clearErrorField('website')
    companyDispatch(setCompanyWebsite(value))
  }

  const handleSetAddress = (value: LocationType) => {
    companyDispatch(setCompanyAddress(value.address))
  }

  const handleWebsiteVisible = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    companyDispatch(setWebsiteVisible(checked))
  }

  const handleAddressVisible = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    companyDispatch(setAddressVisible(checked))
  }

  const handleSaveInCatalog = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => companyDispatch(setSaveInCatalog(checked))

  const checkCompanyValidation = () => {
    const isWebsiteValid = validator.isURL(website)
    const isNameValid = name.length > 3

    if (!isNameValid || !isWebsiteValid) {
      setErrors({
        name: !isNameValid,
        website: !isWebsiteValid,
      })
    }

    return isWebsiteValid && isNameValid
  }

  const handleCancelBtn = () => {
    dispatch(setIsCompanyPanel(false))
  }

  const handleConfirmBtn = () => {
    if (!checkCompanyValidation()) {
      return
    }

    const companyData = removeEmptyFields({
      id,
      logo,
      name,
      website,
      address,
      websiteVisible,
      addressVisible,
      saveInCatalog,
      ...(companyType === 'production' ? { primaryMembers } : {}),
    })

    if (id) {
      dispatch(updateCompanyRequest(id, companyData as Company))
      return
    }

    dispatch(saveCompanyRequest(companyData as Company))
  }

  useEffect(() => {
    dispatch(getCompaniesRequestSuccess([]))
  }, [])

  return (
    <Box sx={style.wrapper}>
      <Box sx={style.content}>
        <TextHead
          title={`${companyType} company details`}
          subtitle={`Please, add all the required information to add a ${companyType} company.`}
        />
        <SearchField
          required
          id='production-company'
          label={`${companyType} company`}
          placeholder={`Type ${companyType} company name...`}
          onInputChange={handleCompanySearch}
          value={{ name }}
          error={errors.name}
          options={companies}
          requestStatus={getRequestStatus}
          getOptionLabel={(option: Company) => option.name}
          PopperComponent={props => <Popper {...props} sx={style.popper} />}
          PaperComponent={props => <Paper {...props} sx={style.paper} />}
          renderOption={(
            props: React.HTMLAttributes<HTMLLIElement>,
            option: Company
          ) => (
            <CompanyOption
              props={props}
              option={option}
              dispatch={companyDispatch}
              key={option.name}
            />
          )}
        />
        <ImageUploadField
          label={companyType}
          onChange={handleSetLogo}
          defaultValue={logo}
        />
        <CustomTextField
          required
          id='website-input'
          label='Website'
          LabelIcon={LinkIcon}
          placeholder={`Type ${companyType} website...`}
          checkbox='Show on CallSheet'
          checked={websiteVisible}
          onInputChange={handleSetWebsite}
          onCheckboxChange={handleWebsiteVisible}
          value={website}
          error={errors.website}
        />
        <Box sx={{ mt: 1 }}>
          <LocationAutocomplete
            id='address-input'
            label='Address'
            LabelIcon={MapPinIcon}
            placeholder='Search Address...'
            checkbox='Show on CallSheet'
            checked={addressVisible}
            onInputChange={handleSetAddress}
            onCheckboxChange={handleAddressVisible}
            onClear={() => companyDispatch(setCompanyAddress(''))}
            location={address}
          />
        </Box>
        {companyType === 'production' ? (
          <MemberField members={primaryMembers} />
        ) : null}
      </Box>
      <Box sx={style.footer}>
        <SaveToCatalogField
          companyType={companyType}
          onChange={handleSaveInCatalog}
          checked={saveInCatalog}
        />
        <ControlButtons
          confirmTitle={`Add ${companyType} Company`}
          handleCancelBtn={handleCancelBtn}
          handleConfirmBtn={handleConfirmBtn}
          requestStatus={saveRequestStatus}
        />
      </Box>
      <SidePanel isOpen={isMembersPanel} onClose={handleMembersPanel}>
        <MembersForm
          companyDispatch={companyDispatch}
          companyId={id}
          members={primaryMembers}
        />
      </SidePanel>
    </Box>
  )
}

export default CompanyForm
