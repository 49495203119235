import React, { FC } from 'react'
import { Box } from '@mui/material'
import {
  EmailIcon,
  InstagramIcon,
  UnsplashIcon,
  WebsiteIcon,
} from '../../assets/icons'

import style from './styles'
import SocialLink from '../SocialLink/SocialLink'

type SocialLinksProps = {
  email: string
  instagramLink?: string
  unsplashLink?: string
  personalLink?: string
}

const SocialLinks: FC<SocialLinksProps> = ({
  instagramLink,
  unsplashLink,
  email,
  personalLink,
}) => (
  <Box sx={style.root} display='flex'>
    {instagramLink ? (
      <SocialLink link={instagramLink} type='website'>
        <InstagramIcon />
      </SocialLink>
    ) : null}
    {unsplashLink ? (
      <SocialLink link={unsplashLink} type='website'>
        <UnsplashIcon />
      </SocialLink>
    ) : null}
    {personalLink ? (
      <SocialLink link={personalLink} type='website'>
        <WebsiteIcon />
      </SocialLink>
    ) : null}
    <SocialLink link={email} type='email'>
      <EmailIcon />
    </SocialLink>
  </Box>
)

export default SocialLinks
