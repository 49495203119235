import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { RequestStatuses } from '../../api/constants'
import Loader from '../../components/Loader'
import { getCallsheetRequest } from '../../store/Callsheet/actions'
import {
  selectCallsheetTitle,
  selectRequestStatus,
} from '../../store/Callsheet/selectors'
import CompaniesResultBox from '../BuilderPage/ProjectOverview/OverviewResult/CompaniesResultBox'

const CallsheetViewPage = () => {
  const dispatch = useDispatch()
  const params: { id: string } = useParams()
  const callsheetTitle = useSelector(selectCallsheetTitle)
  const requestStatus = useSelector(selectRequestStatus)

  useEffect(() => {
    if (params.id) {
      dispatch(getCallsheetRequest(params.id))
    }
  }, [])

  return (
    <Box>
      {requestStatus === RequestStatuses.PENDING ? (
        <Loader />
      ) : (
        <Box>
          <Typography variant='h3'>{callsheetTitle}</Typography>
          <CompaniesResultBox />
        </Box>
      )}
    </Box>
  )
}

export default CallsheetViewPage
