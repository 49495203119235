import { noop } from 'lodash'
import React, { FC, PropsWithChildren } from 'react'
import { Box, Grid } from '@mui/material'
import BackBtn from '../../BackBtn'
import HeadText from '../../HeadText'
import ControlButtons from '../../ControlButtons'

import style from './style'

type FormWrapperProps = {
  onBackClick: typeof noop
  onConfirm: typeof noop
  isBackBtn?: boolean
  controlButtonsProps: {
    confirmTitle: string
    cancelTitle?: string
    isDisabled?: boolean
  }
}

const ContactDetailsFormWrapper: FC<FormWrapperProps & PropsWithChildren> = ({
  onBackClick,
  onConfirm,
  isBackBtn = true,
  controlButtonsProps,
  children,
}) => {
  const {
    confirmTitle,
    cancelTitle = 'Back to all',
    isDisabled = false,
  } = controlButtonsProps
  return (
    <Box sx={style.wrapper}>
      <Box sx={style.content}>
        <Grid item sx={style.item}>
          {isBackBtn && (
            <BackBtn text='Back to all members' onClick={onBackClick} />
          )}
          <HeadText
            title='Contact details'
            subtitle='Here you can find all the information about particular contact from your list'
          />
          {children}
        </Grid>
      </Box>
      <Box sx={style.footer}>
        <ControlButtons
          disabled={isDisabled}
          confirmTitle={confirmTitle}
          cancelTitle={cancelTitle}
          handleCancelBtn={onBackClick}
          handleConfirmBtn={onConfirm}
        />
      </Box>
    </Box>
  )
}

export default ContactDetailsFormWrapper
