import React from 'react'
import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectCrewCallTime } from '../../../../../store/Callsheet/selectors'

import style from './style'

const CrewCallCard = () => {
  const crewCall = useSelector(selectCrewCallTime)

  return (
    <Box sx={style.container}>
      <Typography sx={style.label}>General Call Time:</Typography>
      {crewCall ? (
        <Box sx={style.time}>{crewCall}</Box>
      ) : (
        <Box sx={style.inputPlaceholder} />
      )}
    </Box>
  )
}

export default CrewCallCard
