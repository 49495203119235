const style = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'space-between',
    height: '100%',
  },
  content: {
    width: '100%',
    height: 'calc(100% - 100px)',
  },
  locationsWrapper: {
    height: 'calc(100% - 100px)',
    overflow: 'scroll',
  },
  locationsBox: {
    mt: '16px',
  },
  addButton: {
    mt: '10px',
    mb: '10px',
    pl: '5px',
  },
  limitBox: {
    p: '16px',
    mt: '10px',
    mb: '30px',
  },
  limitText: {
    fontWeight: 400,
  },
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
    width: '100%',
    '> div': {
      width: '100%',
    },
  },
  popper: {
    '& .MuiPaper-root': {
      padding: '0px',
      border: '0px',
      '& .MuiAutocomplete-listbox': {
        padding: '0px',
      },
    },
  },
  paper: {
    '& .MuiAutocomplete-listbox': {
      '& .MuiAutocomplete-option': {
        marginTop: '4px',
        border: '1px solid #E2E2E2',
        boxShadow: '0px 0px 8px rgba(133, 133, 133, 0.15)',
        borderRadius: '8px',
        width: '100%',
      },
    },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
}

export default style
