const style = {
  wrapper: (height: string) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    border: '2px dashed #E2E2E2',
    borderRadius: '16px',
    height,
  }),
  fileWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '458px',
    padding: '8px 14px',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    marginBottom: 0,
  },
  btnWrapper: {
    display: 'flex',
    gap: '12px',
    svg: {
      cursor: 'pointer',
    },
  },
}

export default style
