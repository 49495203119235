export const PREFIX = 'COMPANIES/'

export const ADD_MEMBER_REQUEST = `${PREFIX}ADD_MEMBER_REQUEST` as const
export const DELETE_MEMBER_REQUEST = `${PREFIX}DELETE_MEMBER_REQUEST` as const
export const UPDATE_COMPANY_MEMBERS_REQUEST =
  `${PREFIX}UPDATE_COMPANY_MEMBERS_REQUEST` as const
export const UPDATE_COMPANY_MEMBERS_REQUEST_SUCCESS =
  `${PREFIX}UPDATE_COMPANY_MEMBERS_REQUEST_SUCCESS` as const
export const UPDATE_COMPANY_MEMBERS_REQUEST_ERROR =
  `${PREFIX}UPDATE_COMPANY_MEMBERS_REQUEST_ERROR` as const
export const SAVE_COMPANY_REQUEST = `${PREFIX}SAVE_COMPANY_REQUEST` as const
export const SAVE_COMPANY_REQUEST_SUCCESS =
  `${PREFIX}SAVE_COMPANY_REQUEST_SUCCESS` as const
export const SAVE_COMPANY_REQUEST_ERROR =
  `${PREFIX}SAVE_COMPANY_REQUEST_ERROR` as const
export const UPDATE_COMPANY_REQUEST = `${PREFIX}UPDATE_COMPANY_REQUEST` as const
export const UPDATE_COMPANY_REQUEST_SUCCESS =
  `${PREFIX}UPDATE_COMPANY_REQUEST_SUCCESS` as const
export const UPDATE_COMPANY_REQUEST_ERROR =
  `${PREFIX}UPDATE_COMPANY_REQUEST_ERROR` as const

export const GET_COMPANIES_BY_NAME_REQUEST =
  `${PREFIX}GET_COMPANIES_BY_NAME_REQUEST` as const

export const GET_COMPANY_BY_ID_REQUEST =
  `${PREFIX}GET_COMPANY_BY_ID_REQUEST` as const

export const GET_COMPANIES_REQUEST_SUCCESS =
  `${PREFIX}GET_COMPANIES_REQUEST_SUCCESS` as const
export const GET_COMPANIES_REQUEST_ERROR =
  `${PREFIX}COMPANY_SEARCH_REQUEST_ERROR` as const

export const GET_COMPANY_REQUEST_SUCCESS =
  `${PREFIX}GET_COMPANY_REQUEST_SUCCESS` as const

export const SET_COMPANY = `${PREFIX}SET_COMPANY` as const
