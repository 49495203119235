const style = {
  container: (expanded: boolean) => ({
    height: expanded ? 'auto' : '170px',
    transition: 'height 0.15s',
    position: 'relative',
    backgroundColor: '#ECF6FF',
    borderRadius: '14px',
    mb: 3,
    p: 3,
    pt: '28px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.06)',
  }),
  divider: {
    width: '1px',
    backgroundColor: '#D5E9F9',
    mx: '24px',
  },
  collapseButton: {
    position: 'absolute',
    width: '100%',
    pb: '10px',
    pr: 1,
    backgroundColor: '#ECF6FF',
    textAlign: 'right',
    bottom: '0px',
    right: '0px',
  },
}

export default style
