import { call, put, select, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import {
  addMemberApi,
  deleteMemberApi,
  getMembersApi,
  updateMemberApi,
} from '../../api/members'
import {
  AddMemberResultType,
  GetMembersResultType,
} from '../../api/members/types'
import { SampleError } from '../../api/types'
import { addUserGroupTitleToMembers } from '../../utils/groupHelpers'
import { selectCallsheetId } from '../Callsheet/selectors'
import { selectGroups } from '../Groups/selectors'
import { GroupType } from '../Groups/types'
import { setNotificationErrorWorker } from '../Notification/sagas'
import {
  getMembersRequestSuccess,
  memberRequest,
  memberRequestError,
  memberRequestSuccess,
} from './actions'
import {
  ADD_MEMBER_REQUEST,
  DELETE_MEMBER_REQUEST,
  GET_MEMBERS_REQUEST,
  UPDATE_MEMBER_REQUEST,
} from './actionTypes'
import {
  AddMemberRequestType,
  DeleteMemberRequestType,
  UpdateMemberRequestType,
} from './types'

export function* AddMemberRequestWorker({
  payload: { userGroupId, contactId, callTime, wrapTime, hidePersonalInfo },
}: AddMemberRequestType) {
  yield put(memberRequest())
  try {
    const {
      data: { data },
    }: AxiosResponse<AddMemberResultType> = yield call(
      addMemberApi,
      userGroupId,
      contactId,
      hidePersonalInfo,
      callTime,
      wrapTime
    )
    yield put(memberRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(memberRequestError())
  }
}

export function* DeleteMemberRequestWorker({
  payload: { memberId },
}: DeleteMemberRequestType) {
  yield put(memberRequest())
  try {
    const {
      data: { data },
    }: AxiosResponse<AddMemberResultType> = yield call(
      deleteMemberApi,
      memberId
    )
    yield put(memberRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(memberRequestError())
  }
}

export function* GetMembersRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)
  const userGroups: GroupType[] = yield select(selectGroups)

  yield put(memberRequest())
  try {
    const {
      data: { data },
    }: AxiosResponse<GetMembersResultType> = yield call(
      getMembersApi,
      callsheetId
    )

    if (data.length) {
      const membersWithGroupTitle = addUserGroupTitleToMembers(data, userGroups)
      yield put(getMembersRequestSuccess(membersWithGroupTitle))
    }
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(memberRequestError())
  }
}

export function* UpdateMemberRequestWorker({
  payload: {
    member: { id, ...rest },
  },
}: UpdateMemberRequestType) {
  yield put(memberRequest())
  try {
    const {
      data: { data },
    }: AxiosResponse<GetMembersResultType> = yield call(
      updateMemberApi,
      id,
      rest
    )
    yield put(memberRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(memberRequestError())
  }
}

export function* requestMembersWatcher(): Generator {
  yield takeLatest(ADD_MEMBER_REQUEST, AddMemberRequestWorker)
  yield takeLatest(DELETE_MEMBER_REQUEST, DeleteMemberRequestWorker)
  yield takeLatest(GET_MEMBERS_REQUEST, GetMembersRequestWorker)
  yield takeLatest(UPDATE_MEMBER_REQUEST, UpdateMemberRequestWorker)
}
