import React, { FC } from 'react'
import { noop } from 'lodash'

import { PlusIcon } from '../../../../../assets/icons'
import { Contact } from '../../../../../store/Contacts/types'
import ContactPanel from '../../../../../components/ContactPanel'
import ContactChips from '../../../../../components/ContactChips'

type ContactItemTypes = {
  contact: Contact
  membersCount: number
  onAddContact: typeof noop
  handleProfileOpen: typeof noop
}

const ContactItem: FC<ContactItemTypes> = ({
  contact,
  membersCount,
  onAddContact,
  handleProfileOpen,
}) => {
  const { logo, firstName, lastName, isFriend, tags } = contact

  return (
    <ContactPanel
      logo={logo}
      name={`${firstName} ${lastName}`}
      onTitleClick={handleProfileOpen}
      btnProps={{
        title: `Add to production`,
        Icon: PlusIcon,
        color: 'text.secondary',
        disabled: membersCount === 2,

        onBtnClick: onAddContact,
      }}
    >
      <ContactChips tags={tags} isFriend={isFriend} />
    </ContactPanel>
  )
}

export default ContactItem
