const style = {
  wrapper: {
    boxShadow: '0px 3px 6px 0px #0000000F',
    padding: 3,
    minWidth: '644px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header_title: {
    display: 'flex',
    alignItems: 'baseline',
    gap: 0,
    'p:first-of-type': {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 900,
      color: 'text.primary',
    },
    'p:nth-of-type(2)': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: 'text.disabled',
    },
  },
  right_items: {
    '> svg': {
      cursor: 'pointer',
    },
    'not(+ svg)': {
      cursor: 'pointer',
    },
    display: 'flex',
    alignItems: 'center',
    gap: '26px',
  },
  content: {
    maxWidth: '300px',
    m: 'auto',
    '> p': {
      mt: 4,
      mb: 1,
      px: 2,
      fontSize: '14px',
    },
  },
}

export default style
