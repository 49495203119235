import { v4 as uuidv4 } from 'uuid'
import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Stack, Typography } from '@mui/material'

import {
  selectWalkieChannels,
  selectWalkieChannelsRequest,
} from '../../../../store/WalkieChannels/selector'
import {
  addWalkieChannel,
  deleteWalkieChannel,
  getWalkieChannelsRequest,
  updateWalkieChannel,
} from '../../../../store/WalkieChannels/actions'
import { WalkieChannel } from '../../../../store/WalkieChannels/types'

import CustomTextButton from '../../../../components/CustomTextButton'
import Loader from '../../../../components/Loader'
import Channel from './Channel'

import {
  isEmptyValues,
  validateChannels,
} from '../../../../utils/walkieChannelsHelpers'
import { DEFAULT_ERRORS_STATE, ErrorState } from './constants'
import { RequestStatuses } from '../../../../api/constants'
import { MAX_CHANNELS_LENGTH } from '../../../../constants'
import { PlusCircle, PlusCircleDisabled } from '../../../../assets/icons'

import style from './style'

const WalkieChannels: FC<{ onError: (isError: boolean) => void }> = ({
  onError,
}) => {
  const channels = useSelector(selectWalkieChannels)
  const channelsRequestStatus = useSelector(selectWalkieChannelsRequest)
  const [errors, setErrors] = useState<ErrorState>(DEFAULT_ERRORS_STATE)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWalkieChannelsRequest())
  }, [])

  const handleAddition = () => {
    const newWalkieChannel = { name: '', channel: '', tmpId: uuidv4() }

    dispatch(addWalkieChannel(newWalkieChannel))
  }
  const clearErrors = (valueType: keyof WalkieChannel) => {
    setErrors(prev => ({
      ...prev,
      [valueType]: false,
    }))
  }

  const handleDelete = (item: WalkieChannel) => () => {
    if (item.tmpId) dispatch(deleteWalkieChannel(item.tmpId))
    if (item.id) dispatch(deleteWalkieChannel(item.id))
    setErrors(DEFAULT_ERRORS_STATE)
  }

  const handleChange =
    (item: WalkieChannel) =>
    (valueType: keyof WalkieChannel, value: string) => {
      clearErrors(valueType)

      if (validateChannels(valueType, value, channels)) {
        setErrors(prev => ({
          ...prev,
          [valueType]: true,
        }))
      }

      if (item.id) {
        dispatch(updateWalkieChannel(item.id, valueType, value))
      }

      if (item.tmpId) {
        dispatch(updateWalkieChannel(item.tmpId, valueType, value))
      }
    }

  const isEmpty = channels.length === 0
  const isDisabled =
    channels.length === MAX_CHANNELS_LENGTH || errors.name || errors.channel

  useEffect(() => {
    if (errors.name || errors.channel || isEmptyValues(channels)) {
      onError(true)
    } else {
      onError(false)
    }
  }, [errors, channels])

  return (
    <Box>
      <Typography variant='h5' sx={style.label}>
        Walkie Channels
      </Typography>
      {channelsRequestStatus === RequestStatuses.PENDING ? (
        <Loader />
      ) : (
        <>
          {isEmpty ? (
            <Box sx={style.btnWrapper}>
              <CustomTextButton
                title='Add Walkie Channel '
                Icon={PlusCircle}
                onClick={handleAddition}
              />
            </Box>
          ) : (
            <Stack gap={0}>
              {channels.map((item, idx) => (
                <Channel
                  key={idx}
                  name={item.name}
                  channel={item.channel}
                  onDelete={handleDelete(item)}
                  onChange={handleChange(item)}
                />
              ))}
              {(errors.name || errors.channel) && (
                <Typography textAlign='left' color='error.main'>
                  Already had a walkie channel with the same name or channel
                </Typography>
              )}
              <Box alignSelf='flex-start'>
                <CustomTextButton
                  disabled={isDisabled}
                  title='Add Walkie Channel'
                  Icon={isDisabled ? PlusCircleDisabled : PlusCircle}
                  onClick={handleAddition}
                />
              </Box>
            </Stack>
          )}
        </>
      )}
    </Box>
  )
}

export default WalkieChannels
