import { AxiosResponse } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  getPublishDetailsApi,
  updatePublishDetailsApi,
} from '../../api/publishDetails'

import { SampleError } from '../../api/types'
import { publishCallsheetRequest } from '../Callsheet/actions'
import { selectCallsheetId } from '../Callsheet/selectors'
import { selectEmailParameters } from '../EmailParameters/selectors'
import { selectSmsParameters } from '../SmsParameters/selectors'

import { EmailParameters } from '../EmailParameters/types'
import { SmsParameters } from '../SmsParameters/types'

import { setNotificationErrorWorker } from '../Notification/sagas'
import {
  getPublishDetailsRequestError,
  getPublishDetailsRequestSuccess,
  savePublishDetailsRequestError,
  savePublishDetailsRequestSuccess,
} from './actions'
import {
  GET_PUBLISH_DETAILS_REQUEST,
  SAVE_PUBLISH_DETAILS_REQUEST,
} from './actionTypes'
import { GetPublishDetailsType } from '../../api/publishDetails/types'
import { setSmsParameters } from '../SmsParameters/actions'
import { setEmailParameters } from '../EmailParameters/actions'
import { SavePublishDetailsRequestType } from './types'
import { PATHS } from '../../constants'

export function* SavePublishDetailsWorker({
  history,
  asDraft = false,
}: SavePublishDetailsRequestType) {
  const id: string = yield select(selectCallsheetId)
  const emailParameters: EmailParameters = yield select(selectEmailParameters)
  const smsParameters: SmsParameters = yield select(selectSmsParameters)

  try {
    const { isSmsReminder, smsReminder, ...restSms } = smsParameters
    const { isEmailReminder, emailReminder, ...restEmail } = emailParameters

    const {
      data: { data },
    }: AxiosResponse<any> = yield call(
      updatePublishDetailsApi,
      id,
      isEmailReminder ? emailParameters : restEmail,
      isSmsReminder ? smsParameters : restSms
    )

    yield put(savePublishDetailsRequestSuccess())
    if (asDraft) {
      yield call(history.push, PATHS.PRIVATE.CALLSHEETS)
    } else {
      yield put(publishCallsheetRequest(history))
    }
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(savePublishDetailsRequestError(data as SampleError))
  }
}

export function* GetPublishDetailsWorker() {
  const id: string = yield select(selectCallsheetId)

  try {
    const {
      data: { data },
    }: AxiosResponse<GetPublishDetailsType> = yield call(
      getPublishDetailsApi,
      id
    )
    const { smsContent, smsFrom, smsReminder, ...emailParameters } = data

    yield put(
      setSmsParameters({
        smsContent,
        smsFrom,
        smsReminder,
        isSmsReminder: !!smsReminder,
      })
    )
    yield put(
      setEmailParameters({
        ...emailParameters,
        isEmailReminder: !!emailParameters.emailReminder,
      })
    )
    yield put(getPublishDetailsRequestSuccess())
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getPublishDetailsRequestError(data as SampleError))
  }
}

export function* requestPublishDetailsWatcher(): Generator {
  yield takeLatest(SAVE_PUBLISH_DETAILS_REQUEST, SavePublishDetailsWorker)
  yield takeLatest(GET_PUBLISH_DETAILS_REQUEST, GetPublishDetailsWorker)
}
