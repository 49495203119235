const style = {
  avatarBox: {
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    '> img': {
      width: '100%',
    },
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    backgroundColor: '#D3DEE7',
    svg: {
      width: '18px',
    },
  },
}

export default style
