import { Divider, Grid, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { Callsheet, CallsheetStatuses } from 'src/store/Callsheet/types'
import CallsheetListItem from './CallsheetListItem'

import style from './style'

const CallsheetList: FC<{ callsheets: Callsheet[] }> = ({ callsheets }) => {
  return (
    <Stack sx={style.wrapper}>
      <Grid container sx={style.header}>
        <Grid item xs={2.5}>
          <Typography sx={style.label}>Title</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography sx={style.label}>Status</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={style.label}> Date</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={style.label}> Production Company</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={style.label}> Invitations</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Stack>
        {callsheets.map(({ title, eventDate, status }, idx: number) => (
          <CallsheetListItem
            title={title}
            eventDate={eventDate}
            companyName='Shaw Productions'
            status={status as CallsheetStatuses}
            divider={!(idx === callsheets.length - 1)}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export default CallsheetList
