import React from 'react'
import { Box, InputAdornment, InputBase, Typography } from '@mui/material'
import style from './style'

const SelectFilterInput = (
  props: JSX.IntrinsicAttributes & { label: string }
) => {
  const { label, ...rest } = props
  return (
    <Box display='flex' alignItems='center'>
      <InputBase
        {...rest}
        sx={style.input}
        startAdornment={
          <InputAdornment position='start'>
            <Typography variant='body2' color='text.main' sx={style.label}>
              {`${label}:`}
            </Typography>
          </InputAdornment>
        }
      />
    </Box>
  )
}

export default SelectFilterInput
