const style = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px',
    minHeight: '48px',
    p: '4px',
    mb: 0,
  },
}

export default style
