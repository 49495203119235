const style = {
  button: {
    fontWeight: 900,
    color: 'text.primary',
    ml: 1,
  },
  item: {
    height: '34px',
    p: 0,
  },

  text: {
    fontWeight: 900,
  },
  textRed: {
    fontWeight: 900,
    color: 'red',
  },
  icon: {
    right: '15px !important',
    top: '6px !important',
    '> svg': {
      width: '10px',
      path: {
        stroke: '#1F1F1F',
      },
    },
  },
}

export default style
