import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import SocialLinks from '../../SocialLinks'

import { CameraIcon } from '../../../assets/icons'
import { TEXTS } from '../../../constants'

import style from './style'

type AvatarHeaderProps = {
  logo?: string
  firstName: string
  lastName: string
  title?: string
  instagramLink?: string
  unsplashLink?: string
  bio?: string
  email: string
  website?: string
}

const AvatarHeader: FC<AvatarHeaderProps> = ({
  logo,
  firstName,
  lastName,
  bio,
  instagramLink,
  unsplashLink,
  title,
  email,
  website,
}) => {
  return (
    <>
      <Box display='flex'>
        <Box sx={style.selectedAvatar}>
          {logo ? (
            <img src={logo} alt='avatar' style={style.avatarImg} />
          ) : (
            <Box sx={style.avatarPlaceholder}>
              <CameraIcon />
            </Box>
          )}
        </Box>
        <Box>
          <Typography sx={style.initials} variant='h5'>
            {firstName} {lastName}
          </Typography>
          <Typography sx={style.selectedTitle}>{title}</Typography>
          <Box sx={style.socialProfiles}>
            <SocialLinks
              instagramLink={instagramLink}
              unsplashLink={unsplashLink}
              personalLink={website}
              email={email}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={style.bio}>
        <Typography sx={style.bioHeader}>Notes</Typography>
        <Typography sx={style.bioContent}>
          {bio || (
            <Typography sx={style.noBioText}>
              {TEXTS.PROFILE_TEXTS.NO_BIO}
            </Typography>
          )}
        </Typography>
      </Box>
    </>
  )
}

export default AvatarHeader
