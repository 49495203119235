import React, { FC } from 'react'
import { Box, TextField } from '@mui/material'
import { noop } from 'lodash'
import LabelWithCheckbox from '../LabelWithCheckbox'
import { LabelWithCheckboxTypes } from '../LabelWithCheckbox/types'
import style from './style'

export type CustomTextFieldTypes = {
  name?: string
  value?: string
  placeholder?: string
  helperText?: string
  error?: boolean
  onInputChange?: typeof noop
  onCheckboxChange?: typeof noop
  labelStyles?: { [key: string]: number | string }
}

const CustomTextField: FC<CustomTextFieldTypes & LabelWithCheckboxTypes> = ({
  id,
  name,
  value,
  placeholder = '',
  helperText,
  onInputChange = noop,
  error,
  required = false,
  label,
  LabelIcon,
  checkbox,
  checked,
  onCheckboxChange = noop,
  labelStyles,
}) => {
  return (
    <Box sx={{ mt: 1 }}>
      <LabelWithCheckbox
        id={id}
        label={label}
        required={required}
        LabelIcon={LabelIcon}
        checkbox={checkbox}
        checked={checked}
        onChange={onCheckboxChange}
        labelStyles={labelStyles}
      />
      <TextField
        id={id}
        fullWidth
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onInputChange}
        error={error}
        helperText={helperText}
        FormHelperTextProps={{ sx: style.helperText }}
      />
    </Box>
  )
}

export default CustomTextField
