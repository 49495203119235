import moment from 'moment'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectGroupMembers } from '../../store/GroupMembers/selectors'
import {
  selectCallsheetTitle,
  selectCrewCallTime,
  selectEventDate,
} from '../../store/Callsheet/selectors'

import DataBox from './DataBox'
import { CheckIcon } from '../../assets/icons'

import { PATHS } from '../../constants'

import style from './style'

const SuccessPage: FC = () => {
  const history = useHistory()
  const members = useSelector(selectGroupMembers)
  const callsheetTitle = useSelector(selectCallsheetTitle)
  const crewCallTime = useSelector(selectCrewCallTime)
  const eventDate = useSelector(selectEventDate)

  const handleReturn = () => {
    history.push(PATHS.PRIVATE.CALLSHEETS)
  }

  const handleView = () => {
    // TODO: should open in view mode
    history.push(PATHS.PRIVATE.CALLSHEETS)
  }
  return (
    <Stack sx={style.wrapper}>
      <Box sx={style.success}>
        <CheckIcon />
      </Box>
      <Stack gap={0} mt='32px'>
        <Typography
          variant='subtitle2'
          color='text.disabled'
          sx={{ textTransform: 'uppercase' }}
        >
          We’re live
        </Typography>
        <Typography variant='h2' color='text.primary'>
          CallSheet Sent!
        </Typography>
        <Typography variant='subtitle1' color='text.primary'>
          You have successfully sent the CallSheet
          <Typography variant='h5' color='#517B9F'>
            {callsheetTitle}
          </Typography>
        </Typography>
      </Stack>
      <Box display='flex' gap='12px' mt='40px'>
        <DataBox
          variant='dateTime'
          firstText={moment(eventDate).format('dddd, MMMM D, YYYY')}
          secondText={crewCallTime}
        />
        <DataBox
          variant='callSheet'
          firstText={`Sent to ${members.length} member`}
          secondText='Copy view link only'
          link
        />
      </Box>
      <Grid container spacing={1} maxWidth='496px' mt='60px'>
        <Grid item xs={6}>
          <Button
            variant='outlined'
            fullWidth
            onClick={handleReturn}
            sx={{ color: 'primary.main', borderColor: 'primary.main' }}
          >
            Return to Home
          </Button>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            variant='contained'
            fullWidth
            onClick={handleView}
            loading={false}
          >
            View Published Callsheet
          </LoadingButton>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default SuccessPage
