import { Company } from '../../../../../store/Companies/types'
import { Contact } from '../../../../../store/Contacts/types'
import {
  PREFILL_COMPANY,
  SET_COMPANY_ID,
  SET_COMPANY_NAME,
  SET_COMPANY_LOGO,
  SET_COMPANY_WEBSITE,
  SET_COMPANY_ADDRESS,
  SET_ADDRESS_VISIBLE,
  SET_WEBSITE_VISIBLE,
  SET_SAVE_IN_CATALOG,
  SET_MEMBERS,
} from './actionTypes'

import {
  PrefillCompany,
  SetCompanyId,
  SetCompanyName,
  SetCompanyLogo,
  SetCompanyWebsite,
  SetCompanyAddress,
  SetWebsiteVisible,
  SetAddressVisible,
  SetSaveInCatalog,
  SetMembers,
} from './types'

export const prefillCompany = (company: Company): PrefillCompany => ({
  type: PREFILL_COMPANY,
  company,
})

export const setCompanyId = (id: string): SetCompanyId => ({
  type: SET_COMPANY_ID,
  id,
})

export const setCompanyName = (name: string): SetCompanyName => ({
  type: SET_COMPANY_NAME,
  name,
})

export const setCompanyLogo = (logo: string): SetCompanyLogo => ({
  type: SET_COMPANY_LOGO,
  logo,
})

export const setCompanyWebsite = (website: string): SetCompanyWebsite => ({
  type: SET_COMPANY_WEBSITE,
  website,
})

export const setCompanyAddress = (address: string): SetCompanyAddress => ({
  type: SET_COMPANY_ADDRESS,
  address,
})

export const setWebsiteVisible = (
  websiteVisible: boolean
): SetWebsiteVisible => ({
  type: SET_WEBSITE_VISIBLE,
  websiteVisible,
})

export const setAddressVisible = (
  addressVisible: boolean
): SetAddressVisible => ({
  type: SET_ADDRESS_VISIBLE,
  addressVisible,
})

export const setSaveInCatalog = (saveInCatalog: boolean): SetSaveInCatalog => ({
  type: SET_SAVE_IN_CATALOG,
  saveInCatalog,
})

export const setMembers = (members: Contact[]): SetMembers => ({
  type: SET_MEMBERS,
  members,
})
