import { noop } from 'lodash'
import React, { FC, useCallback } from 'react'

import {
  Box,
  Button,
  Divider,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
} from '@mui/material'

import { SelectChangeEvent } from '@mui/material/Select'
import { CaretBottomIcon } from '../../../../assets/icons'
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox'
import SelectFilterInput from './SelectFilterInput'
import { DEFAULT_TAG, MenuProps, MISSING_TAG } from './constants'
import style from './style'

type GroupsFilterProps = {
  selectedTags: string[]
  tags?: string[]
  isSelectedByMissing?: boolean
  setSelectedTagByMissing?: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedTags: typeof noop
}

const GroupsFilter: FC<GroupsFilterProps> = ({
  selectedTags,
  setSelectedTags,
  setSelectedTagByMissing,
  tags = [],
  isSelectedByMissing = false,
}) => {
  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event
    setSelectedTags(typeof value === 'string' ? value.split(',') : value)
  }

  const handleShowAll = useCallback(() => {
    setSelectedTags([])
    setSelectedTagByMissing && setSelectedTagByMissing(false)
  }, [])

  const handleMissing = () => {
    setSelectedTagByMissing && setSelectedTagByMissing(prev => !prev)
  }

  const renderValue = (selected: string[]) => {
    if (isSelectedByMissing) {
      return MISSING_TAG
    }
    if (isSelectedByMissing && selectedTags.length) {
      return [...selected, MISSING_TAG].join(', ')
    }
    return selectedTags.length === 0 ? DEFAULT_TAG : selected.join(', ')
  }

  return (
    <div>
      <FormControl>
        <Select
          id='filter-multiple-checkbox'
          multiple
          displayEmpty
          value={selectedTags}
          onChange={handleChange}
          MenuProps={MenuProps}
          input={<SelectFilterInput />}
          renderValue={renderValue}
          IconComponent={props => (
            <Box {...props} sx={style.icon}>
              <CaretBottomIcon />
            </Box>
          )}
        >
          {[
            <Button key='show-all' sx={style.button} onClick={handleShowAll}>
              {DEFAULT_TAG}
            </Button>,
            ...tags.map(tag => (
              <MenuItem key={tag} value={tag} sx={style.item}>
                <CustomCheckbox checked={selectedTags.indexOf(tag) > -1} />
                <ListItemText
                  primary={
                    <span>
                      Filter by <b style={style.text}>{tag}</b>
                    </span>
                  }
                />
              </MenuItem>
            )),
            <Box
              sx={{ display: setSelectedTagByMissing ? 'inherit' : 'none' }}
              onClick={handleMissing}
              key={DEFAULT_TAG}
            >
              <Divider />
              <MenuItem value={MISSING_TAG} sx={style.item}>
                <CustomCheckbox checked={isSelectedByMissing} />
                <ListItemText
                  primary={
                    <span>
                      Filter by <b style={style.textRed}>Missing details</b>
                    </span>
                  }
                />
              </MenuItem>
            </Box>,
          ]}
        </Select>
      </FormControl>
    </div>
  )
}

export default GroupsFilter
