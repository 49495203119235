const style = {
  input: {
    width: '165px',
    p: '6px 16px',
    borderRadius: '4px',
    color: 'primary.main',
    fontSize: '14px',
    fontWeight: 800,
    backgroundColor: 'rgba(42, 123, 192, 0.1)',
    '.MuiSelect-select': {
      p: '0px !important',
    },
  },
  label: {
    fontWeight: 900,
    fontSize: '14px',
    lineHeight: '20px',
  },
}

export default style
