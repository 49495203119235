import React, { FC } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectStep } from '../../../../store/Builder/selectors'
import {
  selectCallsheetTitle,
  selectEventDate,
} from '../../../../store/Callsheet/selectors'
import { selectLocations } from '../../../../store/Logistics/selectors'
import { LocationItem } from '../../../../store/Logistics/types'
import { getLocationName } from '../../../../utils/location'
import { STEPS } from '../../constants'
import { EMPTY_LOCATION_ITEM } from '../AddLocationForm/constants'

import TitleHeader from './TitleHeader'
import AddressCard from './AddressCard'
import CompaniesResultBox from './CompaniesResultBox'

import style from './style'

const OverviewResult: FC = () => {
  const step = useSelector(selectStep)
  const callsheetName = useSelector(selectCallsheetTitle)
  const eventDate = useSelector(selectEventDate)
  const locations = useSelector(selectLocations)
  const locationsItems = locations.length
    ? locations
    : [EMPTY_LOCATION_ITEM, EMPTY_LOCATION_ITEM]

  return (
    <Box sx={style.wrapper}>
      <TitleHeader title={callsheetName} date={eventDate} />
      <CompaniesResultBox />
      {step === STEPS.logistic ? (
        <Grid container spacing={2}>
          {locationsItems.map((item: LocationItem, i: number) => {
            const { title, name, city, state, zip, locationNotes, image } = item

            return (
              <Grid item xs={6} key={i}>
                <AddressCard
                  title={title}
                  name={name}
                  address={getLocationName(city, state, zip)}
                  description={locationNotes}
                  image={image}
                />
              </Grid>
            )
          })}
        </Grid>
      ) : null}
    </Box>
  )
}

export default OverviewResult
