import produce from 'immer'
import { RequestStatuses } from '../../api/constants'
import {
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_REQUEST_ERROR,
  ADD_CONTACT_REQUEST_SUCCESS,
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_REQUEST_ERROR,
  GET_CONTACTS_REQUEST_SUCCESS,
  GET_CONTACTS_TAGS_REQUEST,
  GET_CONTACTS_TAGS_REQUEST_ERROR,
  GET_CONTACTS_TAGS_REQUEST_SUCCESS,
  SET_CONTACTS,
} from './actionTypes'
import { ContactsActions, ContactsState } from './types'

const ContactsInitialState: ContactsState = {
  contacts: [],
  tags: [],
  requestStatus: RequestStatuses.UNCALLED,
  requestTagsStatus: RequestStatuses.UNCALLED,
}

const ContactsReducer = produce(
  (draft = ContactsInitialState, action: ContactsActions) => {
    switch (action.type) {
      case GET_CONTACTS_REQUEST:
      case ADD_CONTACT_REQUEST:
        draft.requestStatus = RequestStatuses.PENDING
        break
      case GET_CONTACTS_REQUEST_ERROR:
      case ADD_CONTACT_REQUEST_ERROR:
        draft.requestStatus = RequestStatuses.FAILED
        break
      case ADD_CONTACT_REQUEST_SUCCESS:
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_CONTACTS_REQUEST_SUCCESS:
        draft.contacts = action.contacts
        draft.requestStatus = RequestStatuses.SUCCEEDED
        break
      case GET_CONTACTS_TAGS_REQUEST:
        draft.requestTagsStatus = RequestStatuses.PENDING
        break
      case GET_CONTACTS_TAGS_REQUEST_SUCCESS:
        draft.tags = action.tags
        draft.requestTagsStatus = RequestStatuses.SUCCEEDED
        break
      case GET_CONTACTS_TAGS_REQUEST_ERROR:
        draft.requestTagsStatus = RequestStatuses.FAILED
        break
      case SET_CONTACTS:
        draft.contacts = action.contacts
        break
      default:
        return draft
    }
  }
)

export default ContactsReducer
