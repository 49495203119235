import {
  CallsheetStatuses,
  CallsheetStatusType,
} from '../../store/Callsheet/types'

const style = {
  card: (status: CallsheetStatusType) => ({
    width: 270,
    height: 190,
    border: '1px solid #E2E2E2',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: '8px',
    boxShadow: '0px 5px 8px rgba(133, 133, 133, 0.1)',
    opacity: status === CallsheetStatuses.DECLINED ? 0.5 : 1,
    mr: 3,
    mb: 3,
  }),
  optionsBtn: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    zIndex: 100,
  },
  header: {
    padding: '12px',
  },
  title: {
    mb: '4px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 900,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '30px',
  },
  dateBox: {
    display: 'flex',
    alignItems: 'center',
  },
  dateText: {
    fontWeight: 900,
    fontSize: '12px',
    color: 'info.main',
    ml: 0,
  },
  body: {
    display: 'flex',
    padding: '4px 12px 12px 12px',
  },
  author: {
    pl: 1,
    fontSize: '10px',
    fontWeight: 900,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  status: {
    color: 'text.disabled',
    fontSize: '12px',
    px: '8px',
    '& .MuiChip-label': {
      textTransform: 'lowercase',
    },
  },
  footer: {
    padding: '12px',
  },
}

export default style
