import { AxiosPromise } from 'axios'
import { Contact } from '../../store/Contacts/types'
import { apiClient, networkApiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const getContactsApi = (): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.NETWORK,
  })

export const addContactApi = (
  userId: string,
  contact: Omit<Contact, 'id'>
): AxiosPromise =>
  networkApiClient.request({
    method: METHODS.POST,
    url: URLS.CONTACT,
    data: {
      userId,
      ...contact,
    },
  })

export const updateContactApi = (
  userId: string,
  contact: Contact
): AxiosPromise =>
  networkApiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CONTACT}/${userId}/${contact.id}`,
    data: contact,
  })

export const addContactImageApi = (
  userId: string,
  contactId: string,
  formData: FormData
): AxiosPromise => {
  return networkApiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CONTACT}/image/${userId}/${contactId}`,
    data: formData,
  })
}
