import { noop } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import ImageUploading, { ImageListType } from 'react-images-uploading'

import { prepareImageValue, stripBase64Data } from '../../../../utils/image'
import { getFileSizeKB } from '../../../../utils/getFileSizeKB'

import {
  DocumentOutlineIcon,
  EditIcon,
  TrashcanIcon,
} from '../../../../assets/icons'
import UploadFileButton from '../../../../components/UploadFileButton'

import style from './style'

type FileUploadFieldTypes = {
  onChange: typeof noop
  defaultValue?: string
}

const FileUploadField: FC<FileUploadFieldTypes> = ({
  onChange,
  defaultValue,
}) => {
  const [file, setFile] = useState(prepareImageValue(defaultValue))

  useEffect(() => {
    setFile(prepareImageValue(defaultValue))
  }, [defaultValue])

  const handleChange = (imageList: ImageListType) => {
    setFile(imageList as never[])
    onChange(imageList[0] && imageList.map(img => stripBase64Data(img.dataURL)))
  }

  return (
    <ImageUploading value={file} onChange={handleChange} multiple>
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
        onImageRemove,
        dragProps,
      }) => (
        <Box>
          {imageList.map((img, idx) => (
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='flex-start'
              key={idx}
            >
              <Box sx={style.fileWrapper}>
                <Box display='flex' gap='12px'>
                  <DocumentOutlineIcon />
                  <Stack alignItems='flex-start'>
                    <Typography
                      variant='subtitle2'
                      color='primary.main'
                      lineHeight='20px'
                    >
                      {`File name #${img.file?.name}`}
                    </Typography>
                    <Typography variant='body1' color='text.disabled'>
                      {`${getFileSizeKB(img)}KB`}
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={style.btnWrapper}>
                  <EditIcon onClick={() => onImageUpdate(idx)} />
                  <TrashcanIcon onClick={() => onImageRemove(idx)} />
                </Box>
              </Box>
            </Box>
          ))}

          <Box
            className='upload__image-wrapper'
            sx={style.wrapper(file.length ? '118px' : '76px')}
            {...dragProps}
          >
            <UploadFileButton
              onClick={onImageUpload}
              tip='Supports: JPEG, JPG, PNG, PDF'
              size={file.length ? 'large' : 'small'}
            />
          </Box>
        </Box>
      )}
    </ImageUploading>
  )
}

export default FileUploadField
