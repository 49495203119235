import { UsersIcon, SquaresFourIcon, HomeIcon } from '../../assets/icons'
import { PATHS } from '../../constants'

export const SIDE_MENU_ITEMS = [
  {
    title: 'Dashboard',
    Icon: HomeIcon,
    link: PATHS.PRIVATE.DASHBOARD,
    isDisabled: true,
  },
  {
    title: 'Callsheets',
    Icon: SquaresFourIcon,
    link: PATHS.PRIVATE.CALLSHEETS,
    isDisabled: false,
  },
  {
    title: 'Network',
    Icon: UsersIcon,
    link: PATHS.PRIVATE.NETWORK,
    isDisabled: true,
  },
]
