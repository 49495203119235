import React, { FC, useState } from 'react'
import { noop } from 'lodash'

import { DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextField, TextFieldProps } from '@mui/material'
import CustomActionBar from './CustonActionBar/CustomActionBar'

import style from './style'

type DatePickerTypes = {
  onChange: typeof noop
  value: DateConstructor | null
}

const DatePickerInput: FC<DatePickerTypes> = ({ onChange, value }) => {
  const [open, setOpen] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        inputFormat='dddd, MMM DD, yyyy'
        onChange={onChange}
        OpenPickerButtonProps={{ sx: { display: 'none' } }}
        disablePast
        components={{ ActionBar: CustomActionBar }}
        componentsProps={{
          actionBar: { actions: ['today'] },
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...{
              ...params,
              inputProps: {
                ...params.inputProps,
                placeholder: 'Add Date',
              },
            }}
            onKeyDown={e => e.preventDefault()}
            onClick={() => setOpen(true)}
            size='small'
            InputProps={{
              sx: { ...style.input, width: value ? '200px' : '92px' },
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePickerInput
