import React, { ChangeEventHandler, FC, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useToggle } from '../../../../../hooks/useToggle'

import SidePanel from '../../../../../components/SidePanel'
import ContactPanel from '../../../../../components/ContactPanel'
import ContactChips from '../../../../../components/ContactChips'
import MemberProfile from '../../../ProjectOverview/MembersForm/MemberProfile'

import { selectPreselectedGroupMembers } from '../../../../../store/GroupMembers/selectors'
import { selectGroups } from '../../../../../store/Groups/selectors'
import { Contact } from '../../../../../store/Contacts/types'
import { addPreselectedGroupMember } from '../../../../../store/GroupMembers/actions'
import { CallTime } from '../../../../../store/GroupMembers/types'

import { SELECT_GROUP_PLACEHOLDER } from '../../../../../utils/groupMemberHelpers'
import {
  getGroupIdByTitle,
  getGroupTitles,
} from '../../../../../utils/groupHelpers'

type ContactItemTypes = {
  contact: Contact
  isPrimary?: boolean
}

const GroupContactItem: FC<ContactItemTypes> = ({
  contact,
  isPrimary = false,
}) => {
  const dispatch = useDispatch()
  const { logo, firstName, lastName, isFriend, tags, email } = contact
  const [isOpen, toggleProfile] = useToggle(false)
  const userGroups = useSelector(selectGroups)
  const groupMembers = useSelector(selectPreselectedGroupMembers)

  const groupMember = groupMembers.find(item => item.email === email)

  const [callTime, setCallTime] = useState(
    groupMember ? groupMember.callTime : ''
  )
  const [wrapTime, setWrapTime] = useState(
    groupMember ? groupMember.wrapTime : ''
  )

  const createdGroups = useSelector(selectGroups).filter(
    group => group.isSelected
  )

  const defaultGroup = groupMember
    ? groupMember.groupTitle ?? SELECT_GROUP_PLACEHOLDER
    : SELECT_GROUP_PLACEHOLDER

  const handleSelect = (groupTitle: string) => {
    // check if we have this contact saved as a member
    const savedMember = groupMembers.find(
      member => member.contactId === contact.id
    )
    if (savedMember) {
      dispatch(
        addPreselectedGroupMember({
          ...savedMember,
          groupTitle,
          isUpdated: groupTitle !== SELECT_GROUP_PLACEHOLDER,
          newUserGroupId: getGroupIdByTitle(userGroups, groupTitle),
        })
      )

      return
    }

    dispatch(
      addPreselectedGroupMember({
        ...contact,
        callTime,
        wrapTime,
        hidePersonalInfo: false,
        isPrimary,
        groupTitle,
      })
    )
  }

  const handleCallTime =
    (time: CallTime): ChangeEventHandler<HTMLTextAreaElement> =>
    ({ target: { value } }) => {
      if (time === CallTime.START_TIME) {
        setCallTime(value)
        return
      }
      setWrapTime(value)
    }

  return (
    <>
      <ContactPanel
        logo={logo}
        name={`${firstName} ${lastName}`}
        selectProps={{
          label: SELECT_GROUP_PLACEHOLDER,
          defaultValue: defaultGroup,
          values: getGroupTitles(createdGroups),
          onSelect: handleSelect,
        }}
        onTitleClick={toggleProfile}
      >
        <ContactChips tags={tags} isFriend={isFriend} />
      </ContactPanel>
      <SidePanel isOpen={isOpen} onClose={toggleProfile}>
        <MemberProfile
          onBackClick={toggleProfile}
          onConfirmClick={toggleProfile}
          member={contact}
          groupMember={{
            callTime,
            wrapTime,
            onCallTimeChange: handleCallTime(CallTime.START_TIME),
            onWrapTimeChange: handleCallTime(CallTime.WRAP_TIME),
          }}
          selectProps={{
            label: SELECT_GROUP_PLACEHOLDER,
            defaultValue: defaultGroup,
            values: getGroupTitles(createdGroups),
            onSelect: handleSelect,
          }}
        />
      </SidePanel>
    </>
  )
}

export default GroupContactItem
