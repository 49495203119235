const style = {
  iconBtn: {
    p: '0px',
  },
  menu: {
    '& .MuiPaper-root': {
      width: '200px',
    },
  },
  menuItem: {
    fontSize: '14px',
    fontWeight: 400,
  },
}

export default style
