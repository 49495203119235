import { Box, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import InvitationStatusBox from 'src/components/InvitationStatusBox'
import {
  CrossIcon,
  HelpCircleOutlineIcon,
  CheckCircleGreenIcon,
} from '../../../../../assets/icons'

import style from './style'

const statuses = {
  decline: {
    title: 'Decline',
    icon: <CrossIcon />,
    color: '#CC2E26',
  },
  tentative: {
    title: 'Tentative',
    icon: <HelpCircleOutlineIcon />,
    color: '#FA6400',
  },
  accept: {
    title: 'Accept',
    icon: <CheckCircleGreenIcon />,
    color: '#2C9A5A',
  },
}

const StatusBox: FC<{ status: keyof typeof statuses }> = ({ status }) => {
  return (
    <Box sx={style.statusWrapper(statuses[status].color)}>
      <Typography sx={style.status(statuses[status].color)}>
        {statuses[status].title}
      </Typography>
      {statuses[status].icon}
    </Box>
  )
}

const CallTimeBox: FC = () => {
  return (
    <Stack sx={style.wrapper}>
      <Typography sx={style.callTime}>10:30PM</Typography>
      <Typography variant='subtitle2' color='text.secondary'>
        YOUR CALL TIME
      </Typography>
      <Box display='flex' width='322px' justifyContent='space-between'>
        <InvitationStatusBox status='decline' withIcon />
        <InvitationStatusBox status='tentative' withIcon />
        <InvitationStatusBox status='accept' withIcon />
      </Box>
    </Stack>
  )
}

export default CallTimeBox
