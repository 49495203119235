import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Paper, InputBase, Typography } from '@mui/material'
import { useToggle } from '../../../../hooks/useToggle'

import { PlusCircle, TrashcanIcon } from '../../../../assets/icons'
import {
  setProductionNotesDetails,
  setProductionNotesTitle,
} from '../../../../store/Callsheet/actions'
import {
  selectCalsheetProductionNotes,
  selectCalsheetProductionNotesTitle,
} from '../../../../store/Callsheet/selectors'

import style from './style'

enum ValueType {
  NOTE = 'note',
  TITLE = 'title',
}

const ProductionNotes = () => {
  const dispatch = useDispatch()
  const note = useSelector(selectCalsheetProductionNotes)
  const title = useSelector(selectCalsheetProductionNotesTitle)
  const [isTitle, toggleTitle] = useToggle(!!title)
  const [value, setValue] = useState<{ note: string; title: string }>({
    note,
    title,
  })

  const handleValueChange =
    (valueType: ValueType) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(prev => ({ ...prev, [valueType]: e.target.value }))
      if (valueType === ValueType.NOTE) {
        dispatch(setProductionNotesDetails(e.target.value))
      } else {
        dispatch(setProductionNotesTitle(e.target.value))
      }
    }

  const handleTitle = () => {
    toggleTitle()
    setValue(prev => ({ ...prev, title: '' }))
    dispatch(setProductionNotesTitle(''))
  }

  return (
    <Box>
      <Typography variant='h5' sx={style.label}>
        Production Notes
      </Typography>
      <Paper variant='outlined' sx={style.wrapper(!!value.note || isTitle)}>
        <InputBase
          sx={{ flex: 1, p: 0 }}
          value={value.note}
          onChange={handleValueChange(ValueType.NOTE)}
          placeholder='Start typing notes for random'
          inputProps={{
            'aria-label': 'typing notes',
          }}
          multiline
        />
        {isTitle && (
          <InputBase
            sx={style.title}
            value={value.title}
            autoFocus
            onChange={handleValueChange(ValueType.TITLE)}
            placeholder='Start typing..'
            inputProps={{
              'aria-label': 'title',
            }}
          />
        )}
        <Box sx={style.titleWrapper}>
          <Box sx={style.titleBtn(isTitle)} onClick={handleTitle}>
            <Typography variant='body2'>
              {`${isTitle ? 'Delete' : 'Add'} title`}
            </Typography>
            {isTitle ? <TrashcanIcon /> : <PlusCircle />}
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default ProductionNotes
