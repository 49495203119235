import { SampleError } from 'src/api/types'
import {
  ADD_CHANNEL,
  ADD_CHANNEL_REQUEST,
  DELETE_CHANNEL,
  DELETE_CHANNEL_REQUEST,
  GET_CHANNEL_REQUEST,
  GET_CHANNEL_REQUEST_ERROR,
  GET_CHANNEL_REQUEST_SUCCESS,
  SAVE_CHANNEL_REQUEST,
  SAVE_CHANNEL_REQUEST_ERROR,
  SAVE_CHANNEL_REQUEST_SUCCESS,
  UPDATE_CHANNEL,
  UPDATE_CHANNEL_REQUEST,
} from './actionTypes'
import {
  AddWalkieChannel,
  AddWalkieChannelRequestType,
  DeleteWalkieChannel,
  DeleteWalkieChannelRequestType,
  GetWalkieChannelRequestErrorType,
  GetWalkieChannelRequestSuccessType,
  GetWalkieChannelRequestType,
  SaveWalkieChannelRequestErrorType,
  SaveWalkieChannelRequestSuccessType,
  SaveWalkieChannelRequestType,
  UpdateWalkieChannel,
  UpdateWalkieChannelRequestType,
  WalkieChannel,
} from './types'

export const getWalkieChannelsRequest = (): GetWalkieChannelRequestType => ({
  type: GET_CHANNEL_REQUEST,
})

export const getWalkieChannelsSuccessRequest = (
  walkieChannels: WalkieChannel[]
): GetWalkieChannelRequestSuccessType => ({
  type: GET_CHANNEL_REQUEST_SUCCESS,
  walkieChannels,
})

export const getWalkieChannelsError = (
  error: SampleError
): GetWalkieChannelRequestErrorType => ({
  type: GET_CHANNEL_REQUEST_ERROR,
  error,
})

export const addWalkieChannel = (
  walkieChannel: WalkieChannel
): AddWalkieChannel => ({
  type: ADD_CHANNEL,
  walkieChannel,
})

export const addWalkieChannelRequest = (
  callsheetId: string,
  name: string,
  channel: string
): AddWalkieChannelRequestType => ({
  type: ADD_CHANNEL_REQUEST,
  payload: {
    callsheetId,
    name,
    channel,
  },
})

export const deleteWalkieChannelRequest = (
  id: string
): DeleteWalkieChannelRequestType => ({
  type: DELETE_CHANNEL_REQUEST,
  payload: {
    id,
  },
})

export const updateWalkieChannelRequest = (
  id: string,
  name: string,
  channel: string
): UpdateWalkieChannelRequestType => ({
  type: UPDATE_CHANNEL_REQUEST,
  payload: {
    id,
    name,
    channel,
  },
})

export const saveWalkieChannelsRequest = (): SaveWalkieChannelRequestType => ({
  type: SAVE_CHANNEL_REQUEST,
})

export const saveWalkieChannelsRequestSuccess =
  (): SaveWalkieChannelRequestSuccessType => ({
    type: SAVE_CHANNEL_REQUEST_SUCCESS,
  })

export const saveWalkieChannelsRequestError = (
  error: SampleError
): SaveWalkieChannelRequestErrorType => ({
  type: SAVE_CHANNEL_REQUEST_ERROR,
  error,
})

export const deleteWalkieChannel = (id: string): DeleteWalkieChannel => ({
  type: DELETE_CHANNEL,
  id,
})

export const updateWalkieChannel = (
  id: string,
  valueType: keyof WalkieChannel,
  value: string
): UpdateWalkieChannel => ({
  type: UPDATE_CHANNEL,
  id,
  valueType,
  value,
})
