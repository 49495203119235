import { History } from 'history'
import { RequestStatus, SampleError } from '../../api/types'

import {
  GET_CALLSHEETS_REQUEST,
  GET_CALLSHEETS_REQUEST_ERROR,
  GET_CALLSHEETS_REQUEST_SUCCESS,
  GET_CALLSHEET_BY_ID_REQUEST,
  GET_CALLSHEET_BY_ID_REQUEST_ERROR,
  GET_CALLSHEET_BY_ID_REQUEST_SUCCESS,
  SAVE_CALLSHEET_REQUEST,
  SAVE_CALLSHEET_REQUEST_ERROR,
  SAVE_CALLSHEET_REQUEST_SUCCESS,
  UPDATE_CALLSHEET_REQUEST,
  UPDATE_CALLSHEET_REQUEST_ERROR,
  UPDATE_CALLSHEET_REQUEST_SUCCESS,
  GET_MEMBER_TITLES_REQUEST,
  GET_MEMBER_TITLES_REQUEST_SUCCESS,
  GET_MEMBER_TITLES_REQUEST_ERROR,
  SET_CALLSHEET,
  SET_CALLSHEET_ID,
  SET_CALLSHEET_TITLE,
  SET_EVENT_DATE,
  SET_CREW_CALL_TIME,
  SET_PRODUCT_COMPANY_ID,
  SET_CLIENT_COMPANY_ID,
  SET_IS_COMBINED_NOTES,
  SET_PRODUCTION_NOTE_DETAILS,
  SET_PRODUCTION_NOTE_TITLE,
  SAVE_PRODUCTION_NOTES_REQUEST,
  PUBLISH_CALLSHEET_REQUEST,
  PUBLISH_CALLSHEET_REQUEST_SUCCESS,
} from './actionTypes'

export enum CallsheetStatuses {
  DRAFT = 'DRAFT',
  PENDING_INVITE = 'PENDING_INVITE',
  PUBLISHED = 'PUBLISHED',
  ACCEPTED = 'ACCEPTED',
  TENTATIVE = 'TENTATIVE',
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  DELETED = 'DELETED',
}

export type CallsheetStatusType = keyof typeof CallsheetStatuses

export type Callsheet = {
  id?: string
  ownerId?: string
  combinedNotes?: boolean
  status?: CallsheetStatusType
  title: string
  eventDate: DateConstructor | null
  crewCallTime: string
  productCompanyId: string
  clientCompanyId: string
  productionNoteDetails?: string
  productionNoteTitle?: string
}

export interface CallsheetState {
  callsheet: Callsheet
  callsheets?: Callsheet[]
  memberTitles?: string[]
  requestStatus: RequestStatus
  callsheetsRequestStatus: RequestStatus
  memberTitlesRequestStatus: RequestStatus
}

export interface GetCallsheetsRequestType {
  type: typeof GET_CALLSHEETS_REQUEST
}

export interface GetCallsheetsRequestErrorType {
  type: typeof GET_CALLSHEETS_REQUEST_ERROR
  error: SampleError | null
}

export interface GetCallsheetsRequestSuccessType {
  type: typeof GET_CALLSHEETS_REQUEST_SUCCESS
  callsheets: Callsheet[]
}

export interface GetCallsheetByIdRequestType {
  type: typeof GET_CALLSHEET_BY_ID_REQUEST
  payload: {
    id: string
  }
}

export interface GetCallsheetRequestErrorType {
  type: typeof GET_CALLSHEET_BY_ID_REQUEST_ERROR
  error: SampleError | null
}

export interface GetCallsheetRequestSuccessType {
  type: typeof GET_CALLSHEET_BY_ID_REQUEST_SUCCESS
  callsheet: Callsheet
}

export interface SaveCallsheetRequestType {
  type: typeof SAVE_CALLSHEET_REQUEST
  payload: Callsheet
}

export interface SaveCallsheetRequestErrorType {
  type: typeof SAVE_CALLSHEET_REQUEST_ERROR
  error: SampleError | null
}

export interface SaveCallsheetRequestSuccessType {
  type: typeof SAVE_CALLSHEET_REQUEST_SUCCESS
  id: string
}

export interface UpdateCallsheetRequestType {
  type: typeof UPDATE_CALLSHEET_REQUEST
  payload: Callsheet
}

export interface UpdateCallsheetRequestSuccessType {
  type: typeof UPDATE_CALLSHEET_REQUEST_SUCCESS
  id: string
}

export interface UpdateCallsheetRequestErrorType {
  type: typeof UPDATE_CALLSHEET_REQUEST_ERROR
  error: SampleError | null
}

export interface GetMemberTitlesRequestType {
  type: typeof GET_MEMBER_TITLES_REQUEST
}

export interface GetMemberTitlesRequestSuccessType {
  type: typeof GET_MEMBER_TITLES_REQUEST_SUCCESS
  titles: string[]
}

export interface GetMemberTitlesRequestErrorType {
  type: typeof GET_MEMBER_TITLES_REQUEST_ERROR
  error: SampleError | null
}

export interface SetCallsheet {
  type: typeof SET_CALLSHEET
  callsheet: Callsheet
}

export interface SetCallsheetId {
  type: typeof SET_CALLSHEET_ID
  id: string
}

export interface SetCallsheetTitle {
  type: typeof SET_CALLSHEET_TITLE
  title: string
}

export interface SetEventDate {
  type: typeof SET_EVENT_DATE
  eventDate: DateConstructor
}

export interface SetCrewCallTime {
  type: typeof SET_CREW_CALL_TIME
  crewCallTime: string
}

export interface SetProductCompanyId {
  type: typeof SET_PRODUCT_COMPANY_ID
  productCompanyId: string
}

export interface SetClientCompanyId {
  type: typeof SET_CLIENT_COMPANY_ID
  clientCompanyId: string
}

export interface SetIsCombinedNotes {
  type: typeof SET_IS_COMBINED_NOTES
  combinedNotes: boolean
}

export interface SetProductionNotesDetails {
  type: typeof SET_PRODUCTION_NOTE_DETAILS
  productionNoteDetails: string
}
export interface SetProductionNotesTitle {
  type: typeof SET_PRODUCTION_NOTE_TITLE
  productionNoteTitle: string
}

export interface SaveProductionNotesRequestType {
  type: typeof SAVE_PRODUCTION_NOTES_REQUEST
}

export interface PublishCallsheetRequestType {
  type: typeof PUBLISH_CALLSHEET_REQUEST
  history: History
}

export interface PublishCallsheetRequestSuccessType {
  type: typeof PUBLISH_CALLSHEET_REQUEST_SUCCESS
}

export type CallsheetActions =
  | GetCallsheetsRequestType
  | GetCallsheetsRequestErrorType
  | GetCallsheetsRequestSuccessType
  | GetCallsheetByIdRequestType
  | GetCallsheetRequestErrorType
  | GetCallsheetRequestSuccessType
  | SaveCallsheetRequestType
  | SaveCallsheetRequestErrorType
  | SaveCallsheetRequestSuccessType
  | UpdateCallsheetRequestType
  | UpdateCallsheetRequestSuccessType
  | UpdateCallsheetRequestErrorType
  | GetMemberTitlesRequestType
  | GetMemberTitlesRequestSuccessType
  | GetMemberTitlesRequestErrorType
  | SetCallsheet
  | SetCallsheetId
  | SetCallsheetTitle
  | SetEventDate
  | SetCrewCallTime
  | SetProductCompanyId
  | SetClientCompanyId
  | SetIsCombinedNotes
  | SetProductionNotesDetails
  | SetProductionNotesTitle
  | SaveProductionNotesRequestType
  | PublishCallsheetRequestType
  | PublishCallsheetRequestSuccessType
