const style = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'space-between',
    height: '100%',
  },
  timeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
    width: '100%',
    '> div': {
      width: '100%',
    },
  },
  item: {
    flexBasis: '50%',
    minHeight: 258,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}

export default style
