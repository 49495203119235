const style = {
  root: {
    display: 'flex',
    flexShrink: 0,
    width: 36,
    height: 36,
    background: '#FFFFFF',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    '&:not(:last-child)': {
      margin: '0 8px 0 0',
    },
    '&:disabled': {
      opacity: 0.3,
      cursor: 'unset',
    },
  },
}

export default style
