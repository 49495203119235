import {
  SET_COMPANY_TYPE,
  SET_IS_MEMBER_PROFILE_PANEL,
  SET_IS_ADD_CONTACT_PANEL,
  SET_IS_COMPANY_PANEL,
  SET_IS_GROUP_MEMBERS_PANEL,
  SET_IS_LOCATION_PANEL,
  SET_IS_MEMBERS_PANEL,
  SET_IS_PERSISTENT_PANEL,
  SET_IS_USER_GROUP_PANEL,
  SET_STEP,
} from './actionTypes'
import {
  SetStep,
  SetCompanyType,
  SetIsMemberProfilePanel,
  SetIsAddContactPanel,
  SetIsCompanyPanel,
  SetIsGroupMembersPanel,
  SetIsLocationPanel,
  SetIsMembersPanel,
  SetIsPersistentPanel,
  SetIsUserGroupPanel,
} from './types'

export const setStep = (step: number): SetStep => ({
  type: SET_STEP,
  step,
})

export const setIsPersistentPanel = (
  isPersistentPanel: boolean
): SetIsPersistentPanel => ({
  type: SET_IS_PERSISTENT_PANEL,
  isPersistentPanel,
})

export const setCompanyType = (companyType: string): SetCompanyType => ({
  type: SET_COMPANY_TYPE,
  companyType,
})

export const setIsCompanyPanel = (
  isCompanyPanel: boolean
): SetIsCompanyPanel => ({
  type: SET_IS_COMPANY_PANEL,
  isCompanyPanel,
})

export const setIsMembersPanel = (
  isMembersPanel: boolean
): SetIsMembersPanel => ({
  type: SET_IS_MEMBERS_PANEL,
  isMembersPanel,
})

export const setIsMemberProfilePanel = (
  isMemberProfilePanel: boolean
): SetIsMemberProfilePanel => ({
  type: SET_IS_MEMBER_PROFILE_PANEL,
  isMemberProfilePanel,
})

export const setIsAddContactPanel = (
  isAddContactPanel: boolean
): SetIsAddContactPanel => ({
  type: SET_IS_ADD_CONTACT_PANEL,
  isAddContactPanel,
})

export const setIsLocationPanel = (
  isLocationPanel: boolean
): SetIsLocationPanel => ({
  type: SET_IS_LOCATION_PANEL,
  isLocationPanel,
})

export const setIsUserGroupPanel = (
  isUserGroupPanel: boolean
): SetIsUserGroupPanel => ({
  type: SET_IS_USER_GROUP_PANEL,
  isUserGroupPanel,
})

export const setIsGroupMembersPanel = (
  isGroupMembersPanel: boolean
): SetIsGroupMembersPanel => ({
  type: SET_IS_GROUP_MEMBERS_PANEL,
  isGroupMembersPanel,
})
