import React, { useEffect, useState } from 'react'
import { Box, Card, Divider } from '@mui/material'
import { useSelector } from 'react-redux'
import { CaretBottomIcon, CaretUpIcon } from '../../../../../assets/icons'
import { selectStep } from '../../../../../store/Builder/selectors'
import { selectCompaniesState } from '../../../../../store/Companies/selectors'
import CustomTextButton from '../../../../../components/CustomTextButton'
import { STEPS } from '../../../constants'
import CompanyResultCard from '../CompanyResultCard'
import CrewCallCard from '../CrewCallCard'
import style from './style'

const CompaniesResultBox = () => {
  const step = useSelector(selectStep)
  const { production, client } = useSelector(selectCompaniesState)
  const [expanded, setExpanded] = useState(true)

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    setExpanded(step === STEPS.overview)
  }, [step])

  return (
    <Card sx={style.container(expanded)}>
      {step === STEPS.logistic ? (
        <Box sx={style.collapseButton}>
          <CustomTextButton
            title={`Show ${expanded ? 'Less' : 'More'}`}
            Icon={expanded ? CaretUpIcon : CaretBottomIcon}
            onClick={handleToggle}
          />
        </Box>
      ) : null}
      <Box display='flex'>
        <CompanyResultCard
          logo={production.logo}
          name={production.name}
          members={production.primaryMembers}
          address={production.address}
          website={production.website}
          websiteVisible={production.websiteVisible}
          addressVisible={production.addressVisible}
          companyType='Production'
        />
        <Divider variant='middle' sx={style.divider} />
        <CompanyResultCard
          logo={client.logo}
          name={client.name}
          address={client.address}
          website={client.website}
          websiteVisible={client.websiteVisible}
          addressVisible={client.addressVisible}
          companyType='Client'
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <CrewCallCard />
      </Box>
    </Card>
  )
}

export default CompaniesResultBox
