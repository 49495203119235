const style = {
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '0px',
      '& .MuiAutocomplete-input': {
        padding: '12px 14px',
      },
    },
  },
}

export default style
