import { noop } from 'lodash'
import React, { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'

import style from './style'

type ConfirmDialogProps = {
  isOpen: boolean
  title: string
  content: string
  confirmTitle?: string
  handleCancel: typeof noop
  handleConfirm: typeof noop
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  isOpen,
  title,
  content,
  confirmTitle = 'Ok',
  handleCancel,
  handleConfirm,
}) => {
  return (
    <Dialog open={isOpen}>
      <Stack sx={style.wrapper}>
        <DialogTitle sx={style.title}>{title}</DialogTitle>
        <DialogContent sx={style.content}>{content}</DialogContent>
        <DialogActions sx={style.btn_wrapper}>
          <Button onClick={handleCancel} fullWidth variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleConfirm} fullWidth variant='contained'>
            {confirmTitle}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}

export default ConfirmDialog
