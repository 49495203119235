import { STEPS } from '../../pages/BuilderPage/constants'
import {
  SET_COMPANY_TYPE,
  SET_IS_MEMBER_PROFILE_PANEL,
  SET_IS_ADD_CONTACT_PANEL,
  SET_IS_COMPANY_PANEL,
  SET_IS_GROUP_MEMBERS_PANEL,
  SET_IS_LOCATION_PANEL,
  SET_IS_MEMBERS_PANEL,
  SET_IS_PERSISTENT_PANEL,
  SET_IS_USER_GROUP_PANEL,
  SET_STEP,
} from './actionTypes'
import { BuilderActions, BuilderState } from './types'

const builderInitialState: BuilderState = {
  step: STEPS.overview,
  isPersistentPanel: false,
  isCompanyPanel: false,
  isMembersPanel: false,
  isMemberProfilePanel: false,
  isAddContactPanel: false,
  isLocationPanel: false,
  isUserGroupPanel: false,
  isGroupMembersPanel: false,
  companyType: 'production',
}

const builderReducer = (
  state = builderInitialState,
  action: BuilderActions
): BuilderState => {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.step }
    case SET_IS_PERSISTENT_PANEL:
      return { ...state, isPersistentPanel: action.isPersistentPanel }
    case SET_IS_COMPANY_PANEL:
      return { ...state, isCompanyPanel: action.isCompanyPanel }
    case SET_IS_MEMBERS_PANEL:
      return { ...state, isMembersPanel: action.isMembersPanel }
    case SET_IS_MEMBER_PROFILE_PANEL:
      return { ...state, isMemberProfilePanel: action.isMemberProfilePanel }
    case SET_IS_ADD_CONTACT_PANEL:
      return { ...state, isAddContactPanel: action.isAddContactPanel }
    case SET_IS_LOCATION_PANEL:
      return { ...state, isLocationPanel: action.isLocationPanel }
    case SET_IS_USER_GROUP_PANEL:
      return { ...state, isUserGroupPanel: action.isUserGroupPanel }
    case SET_IS_GROUP_MEMBERS_PANEL:
      return { ...state, isGroupMembersPanel: action.isGroupMembersPanel }
    case SET_COMPANY_TYPE:
      return { ...state, companyType: action.companyType }
    default:
      return state
  }
}

export default builderReducer
