const style = {
  input: {
    border: '1px solid #E2E2E2',
    width: '205px',
    p: '6px 16px',
    borderRadius: '8px',
    color: 'text.main',
    fontSize: '14px',
    fontWeight: 800,

    '.MuiSelect-select': {
      p: '0px !important',
    },
  },
  label: {
    fontWeight: 900,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'text.primary',
  },
}

export default style
