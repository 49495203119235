import { CallsheetStatuses } from 'src/store/Callsheet/types'

const style = {
  status: (status: CallsheetStatuses) => ({
    color: 'text.disabled',
    ...(status === CallsheetStatuses.PUBLISHED && {
      color: '#265A88',
      borderColor: '#265A88',
    }),
    fontSize: '12px',
    fontWeight: 900,
    lineHeight: '20px',
    px: '8px',
    '& .MuiChip-label': {
      textTransform: 'capitalize',
    },
  }),
}

export default style
