import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

import style from './style'

export type Statistics = {
  [key: string]: {
    total: number
    accept: number
    decline: number
    tentative: number
  }
}

// TODO temporary mockup for statistics
const statistics = {
  total: 0,
  accept: 0,
  decline: 0,
  tentative: 0,
}

const CallsheetPublishedFooter: FC<{ id: string }> = ({ id }) => {
  return (
    <Box sx={style.content}>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>Accepted:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.accept}>{statistics.accept || 0}</Box>
        </Box>
      </Box>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>Decline:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.decline}>{statistics.decline || 0}</Box>
        </Box>
      </Box>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>Tentative:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.tentative}>{statistics.tentative || 0}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CallsheetPublishedFooter
