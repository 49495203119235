import React, { useState, FC, useEffect } from 'react'
import { noop } from 'lodash'

import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RequestStatuses } from '../../../../../api/constants'
import {
  setIsAddContactPanel,
  setIsMemberProfilePanel,
} from '../../../../../store/Builder/actions'
import {
  selectIsAddContactPanel,
  selectIsMemberProfilePanel,
} from '../../../../../store/Builder/selectors'
import {
  getContactsRequest,
  getContactsTagsRequest,
  updateContactRequest,
} from '../../../../../store/Contacts/actions'
import {
  selectContactsRequestStatus,
  selectContactsTags,
} from '../../../../../store/Contacts/selectors'
import { Contact, ContactTag } from '../../../../../store/Contacts/types'
import { createFilter } from '../../../../../utils/filters'
import Loader from '../../../../../components/Loader'
import SidePanel from '../../../../../components/SidePanel'
import GroupsFilter from '../../GroupsFilter'
import AddContactForm from '../AddContactForm/AddContactForm'

import ContactItem from '../ContactItem'
import {
  addMembersListItem,
  deleteContactsListItem,
} from '../localStore/actions'
import MemberProfile from '../MemberProfile'
import SearchBox from '../SearchBox'
import CustomTabs from '../../../../../components/CustomTabs'
import CustomScroll from '../../../../../components/CustomScroll'

import { Size, useWindowSize } from '../../../../../hooks/useWindowSize'

const TABS_LABELS = ['Contacts']
const KEYS_BY_NAME = ['firstName', 'lastName', 'email']
const OFFSET = 455

type ContactsBoxProps = {
  membersCount: number
  contacts: Contact[]
  formDispatch: typeof noop
}

const ContactsBox: FC<ContactsBoxProps> = ({
  membersCount,
  contacts,
  formDispatch,
}) => {
  const dispatch = useDispatch()
  const tags: ContactTag[] = useSelector(selectContactsTags)
  const isAddContactPanel = useSelector(selectIsAddContactPanel)
  const isMemberProfilePanel = useSelector(selectIsMemberProfilePanel)
  const requestStatus = useSelector(selectContactsRequestStatus)
  const [selectedContact, setSelectedContact] = useState<Contact>()
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [searchName, setSearchName] = useState('')
  const size: Size = useWindowSize()

  const handleProfileClose = () => {
    dispatch(setIsMemberProfilePanel(false))
  }

  const handleContactUpdate = (member: Contact) => {
    dispatch(updateContactRequest(member))
    dispatch(getContactsRequest())
  }

  const handleAddContactClose = () => {
    dispatch(setIsAddContactPanel(false))
  }

  const handleAddContact = (contact: Contact) => () => {
    if (membersCount === 2) {
      return
    }

    formDispatch(deleteContactsListItem(contact.email))
    formDispatch(addMembersListItem(contact))
  }

  const handleProfileOpen = (contact: Contact) => () => {
    setSelectedContact(contact)
    dispatch(setIsMemberProfilePanel(true))
  }

  const groupsFilter = (arr: Contact[]) => {
    if (!selectedTags.length) {
      return arr
    }

    return arr.filter(contact =>
      contact.tags?.some(tag => selectedTags.includes(tag.name))
    )
  }

  const nameFilter = (list: Contact[]) => {
    return list.filter(createFilter(searchName, KEYS_BY_NAME))
  }

  useEffect(() => {
    if (!tags.length) {
      dispatch(getContactsTagsRequest())
    }
  }, [])

  const containerHeight =
    size.height - (membersCount ? membersCount * 58 + 40 : 0) - OFFSET

  return (
    <Box sx={{ width: '100%' }}>
      <SearchBox onChange={setSearchName} label='Add from Network' />
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        sx={{ mb: 0 }}
      >
        <CustomTabs tabLabels={TABS_LABELS} />
        <GroupsFilter
          tags={tags.map(tag => tag.name)}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </Box>
      <CustomScroll height={containerHeight}>
        {requestStatus === RequestStatuses.PENDING ? (
          <Loader />
        ) : (
          <Box>
            {groupsFilter(nameFilter(contacts)).map(contact => (
              <ContactItem
                key={contact.email}
                contact={contact}
                membersCount={membersCount}
                onAddContact={handleAddContact(contact)}
                handleProfileOpen={handleProfileOpen(contact)}
              />
            ))}
          </Box>
        )}
      </CustomScroll>
      <SidePanel isOpen={isMemberProfilePanel} onClose={handleProfileClose}>
        {selectedContact ? (
          <MemberProfile
            onConfirmClick={handleContactUpdate}
            onBackClick={handleProfileClose}
            member={selectedContact}
          />
        ) : null}
      </SidePanel>
      <SidePanel isOpen={isAddContactPanel} onClose={handleAddContactClose}>
        <AddContactForm />
      </SidePanel>
    </Box>
  )
}

export default ContactsBox
