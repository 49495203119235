export const MAX_FILE_SIZE = 1000000
export const MAX_SITEMAP_SIZE = 5000000
export const MAX_CHANNELS_LENGTH = 20
export const MAX_ANNOUNCEMENTS_NUMBER = 3
export const RETRY_ATTEMPTS = 2
export const RETRY_DELAY = 1000
export const REMIND_BEFORE_PLACEHOLDER = '12 hour'
export const SELECT_PHONE_PLACEHOLDER = 'Select phone number'
export const SEND_EMAIL = 'support@shotlist.com'

export const variables = {
  firstname: '[FIRST_NAME]',
  lastname: '[LAST_NAME]',
  sender: '[SENDER]',
  callsheetName: '[CALLSHEET_NAME]',
  callsheetDate: '[CALLSHEET_DATE]',
  memberCallTime: '[MEMBER_CALLTIME]',
}

export const SELECT_EMAIL_PLACEHOLDER = 'Select email'
export const ERROR_MESSAGES = {
  DEFAULT:
    'There was an error processing your request. Please try again later.',
} as const

export const RESPONSE_ERROR_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
}

export const TEXTS = {
  ERROR_TEXTS: {
    NO_ERROR: '',
    EMAIL_FORMAT_ERROR_TEXT: 'Email is not valid',
    EQUAL_PASSWORDS_ERROR_TEXT:
      'The password and confirmation password do not match. Please try again',
    PASSWORD_LENGTH_ERROR_TEXT:
      'The password must contain: from 8 to 50 characters, uppercase and lowercase letters of latin alphabet and at least 1 special character (e.g. #%^$ etc.)',
    NAME_LENGTH_ERROR_TEXT: 'Use at least 1 character',
    FORGOT_PASSWORD_EMAIL_INVALID_FORMAT: 'Please enter a valid email address',
    FORGOT_PASSWORD_EMAIL_NOT_FOUND:
      'User with such email is not registered. Please enter a valid email',
    EQUAL_EMAIL_ERROR:
      "Email and Email confirmation don't match. Please try again",
    REQUIRED_FIELD: 'This field is required',
  },
  PROFILE_TEXTS: {
    NO_BIO: 'No bio added yet',
  },
}

export const PATHS = {
  PUBLIC: {
    WELCOME: '/',
    LOGIN: '/login',
    SIGN_UP: '/sign-up',
  },
  PRIVATE: {
    HOME: '/',
    DASHBOARD: '/dashboard',
    ACTIVITY: 'activity',
    BUILDER: '/builder',
    CALLSHEETS: '/callsheets',
    NETWORK: '/network',
    SUCCESS: '/success',
    CALLSHEET_VIEW: '/callsheet/:id',
  },
} as const

export const STORAGE_FIELDS = {
  LOCAL: {
    TOKEN: 'token',
  },
} as const

export const PLACEHOLDERS = {
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  RETYPE_PASSWORD: 'Retype Password',
  SIGN_IN_EMAIL: 'Email',
  SIGN_IN_PASSWORD: 'Password',
  // TODO temporary fields
  SIGN_IN_NAME: 'Name',
  SIGN_IN_LAST_NAME: 'Last Name',
}
