import React, { FC } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'

import { noop } from 'lodash'
import { RequestStatuses } from '../../api/constants'

type ControlButtonsTypes = {
  confirmTitle: string
  cancelTitle?: string
  handleCancelBtn: typeof noop
  handleConfirmBtn: typeof noop
  requestStatus?: string
  disabled?: boolean
}

const ControlButtons: FC<ControlButtonsTypes> = ({
  confirmTitle,
  cancelTitle = 'Cancel',
  handleCancelBtn,
  handleConfirmBtn,
  disabled = false,
  requestStatus = RequestStatuses.UNCALLED,
}) => {
  return (
    <Box display='flex' flexWrap='nowrap'>
      <Button
        variant='outlined'
        sx={{ width: '130px' }}
        onClick={handleCancelBtn}
      >
        {cancelTitle}
      </Button>
      <LoadingButton
        disabled={disabled}
        variant='contained'
        fullWidth
        onClick={handleConfirmBtn}
        loading={requestStatus === RequestStatuses.PENDING}
        sx={{ ml: '10px', textTransform: 'capitalize' }}
      >
        {confirmTitle}
      </LoadingButton>
    </Box>
  )
}

export default ControlButtons
