const style = {
  wrapper: (imgSrc: string, altStyle: boolean) => ({
    minWidth: '546px',
    height: '90px',

    borderRadius: '8px',
    padding: '10px',
    paddingRight: 3,

    background: '#ECF6FF',
    ...(altStyle && { backgroundImage: `url(${imgSrc})` }),

    color: 'primary.main',
    display: 'flex',
    justifyContent: 'space-between',
  }),

  titleWrapper: {
    h4: {
      fontSize: '18px',
      fontWeight: 900,
      lineHeight: '28px',
    },

    p: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },

    gap: '6px',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  rect: (altStyle: boolean) => ({
    width: '4px',
    borderRadius: '4px',
    height: '100%',
    backgroundColor: altStyle ? '#A8BDCF' : 'primary.main',
  }),
  editBtn: {
    background: 'white',
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
  },
}

export default style
