import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { selectIsGroupMembersPanel } from '../../../../../store/Builder/selectors'
import { selectIsStackDisplay } from '../../../../../store/Groups/selectors'
import { gridsLong, gridsShort } from './constants'

const MemberHeader: FC = () => {
  const isStackDisplay = useSelector(selectIsStackDisplay)
  const isGroupMembersPanel = useSelector(selectIsGroupMembersPanel)

  return (
    <Grid container>
      <Grid item xs={11}>
        <Grid container>
          {(isGroupMembersPanel || !isStackDisplay
            ? gridsShort
            : gridsLong
          ).map(({ xs, label }) => (
            <Grid item xs={xs} key={label}>
              <Typography variant='body2'>{label}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MemberHeader
