import { call, put, takeLatest, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import {
  getCallsheetByIdApi,
  getCallsheets,
  getMemberTitlesApi,
  publishCallsheetApi,
  saveCallsheetApi,
  updateCallsheetApi,
} from '../../api/callsheet'
import { getContactsApi } from '../../api/contacts'
import { GetContactsResultType } from '../../api/contacts/types'

import { SampleError } from '../../api/types'
import { PATHS } from '../../constants'
import { STEPS } from '../../pages/BuilderPage/constants'
import { setStep } from '../Builder/actions'
import { getCompanyRequestSuccess } from '../Companies/actions'
import { getContactsRequestSuccess } from '../Contacts/actions'
import { setNotificationErrorWorker } from '../Notification/sagas'
import {
  getCallsheetRequestError,
  getCallsheetRequestSuccess,
  getCallsheetsRequestError,
  getCallsheetsRequestSuccess,
  getMemberTitlesRequestError,
  getMemberTitlesRequestSuccess,
  publishCallsheetRequestSuccess,
  saveCallsheetRequestError,
  saveCallsheetRequestSuccess,
  updateCallsheetRequestError,
  updateCallsheetRequestSuccess,
} from './actions'
import {
  GET_CALLSHEETS_REQUEST,
  GET_CALLSHEET_BY_ID_REQUEST,
  GET_MEMBER_TITLES_REQUEST,
  PUBLISH_CALLSHEET_REQUEST,
  SAVE_CALLSHEET_REQUEST,
  SAVE_PRODUCTION_NOTES_REQUEST,
  UPDATE_CALLSHEET_REQUEST,
} from './actionTypes'
import { selectCallsheetId, selectCalsheetData } from './selectors'

import {
  Callsheet,
  GetCallsheetByIdRequestType,
  PublishCallsheetRequestType,
  SaveCallsheetRequestType,
  UpdateCallsheetRequestType,
} from './types'
import {
  GetCallsheetResultType,
  GetCallsheetsResultType,
  GetMemberTitlesResultType,
  SaveCallsheetResultType,
} from '../../api/callsheet/types'

export function* GetCallsheetsRequestWorker() {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetCallsheetsResultType> = yield call(getCallsheets)

    yield put(getCallsheetsRequestSuccess(data))
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getCallsheetsRequestError(data as SampleError))
  }
}

export function* GetCallsheetByIdRequestWorker({
  payload: { id },
}: GetCallsheetByIdRequestType) {
  try {
    const {
      data: {
        data: { productCompany, clientCompany, ...callsheet },
      },
    }: AxiosResponse<GetCallsheetResultType> = yield call(
      getCallsheetByIdApi,
      id
    )
    const {
      data: { data },
    }: AxiosResponse<GetContactsResultType> = yield call(getContactsApi)

    yield put(
      getCallsheetRequestSuccess({
        ...callsheet,
        productCompanyId: productCompany.id,
        clientCompanyId: clientCompany.id,
      })
    )
    yield put(
      getCompanyRequestSuccess(
        {
          ...productCompany,
          primaryMembers: data.filter(contact =>
            productCompany.primaryMembers?.includes(contact.id)
          ),
        },
        'production'
      )
    )
    yield put(getCompanyRequestSuccess(clientCompany, 'client'))
    yield put(getContactsRequestSuccess(data))
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getCallsheetRequestError(data as SampleError))
  }
}

export function* SaveCallsheetRequestWorker({
  payload,
}: SaveCallsheetRequestType) {
  try {
    const {
      data: {
        data: { id },
      },
    }: AxiosResponse<SaveCallsheetResultType> = yield call(
      saveCallsheetApi,
      payload
    )

    yield put(saveCallsheetRequestSuccess(id))
    yield put(setStep(STEPS.logistic))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(saveCallsheetRequestError(data as SampleError))
  }
}

export function* UpdateCallsheetRequestWorker({
  payload,
}: UpdateCallsheetRequestType) {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    const {
      data: {
        data: { id },
      },
    }: AxiosResponse<SaveCallsheetResultType> = yield call(
      updateCallsheetApi,
      callsheetId,
      payload
    )

    yield put(updateCallsheetRequestSuccess(id))
    yield put(setStep(STEPS.logistic))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(updateCallsheetRequestError(data as SampleError))
  }
}

export function* GetMemberTitlesRequestWorker() {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetMemberTitlesResultType> = yield call(getMemberTitlesApi)
    yield put(getMemberTitlesRequestSuccess(data))
  } catch ({ response: data }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getMemberTitlesRequestError(data as SampleError))
  }
}

export function* UpdateCallsheetProductionNotesRequestWorker() {
  const callsheetId: string = yield select(selectCallsheetId)
  const callsheet: Callsheet = yield select(selectCalsheetData)
  const { productionNoteDetails, productionNoteTitle } = callsheet

  try {
    const {
      data: {
        data: { id },
      },
    }: AxiosResponse<SaveCallsheetResultType> = yield call(
      updateCallsheetApi,
      callsheetId,
      {
        ...callsheet,
        ...(productionNoteDetails && { productionNoteDetails }),
        ...(productionNoteTitle && { productionNoteTitle }),
      }
    )

    yield put(updateCallsheetRequestSuccess(id))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(updateCallsheetRequestError(data as SampleError))
  }
}

export function* PublishCallsheetRequestWorker({
  history,
}: PublishCallsheetRequestType) {
  const callsheetId: string = yield select(selectCallsheetId)

  try {
    yield call(publishCallsheetApi, callsheetId)
    yield put(publishCallsheetRequestSuccess())
    yield call(history.push, PATHS.PRIVATE.SUCCESS)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(updateCallsheetRequestError(data as SampleError))
  }
}

export function* requestCallsheetWatcher(): Generator {
  yield takeLatest(GET_CALLSHEETS_REQUEST, GetCallsheetsRequestWorker)
  yield takeLatest(GET_CALLSHEET_BY_ID_REQUEST, GetCallsheetByIdRequestWorker)
  yield takeLatest(SAVE_CALLSHEET_REQUEST, SaveCallsheetRequestWorker)
  yield takeLatest(UPDATE_CALLSHEET_REQUEST, UpdateCallsheetRequestWorker)
  yield takeLatest(
    SAVE_PRODUCTION_NOTES_REQUEST,
    UpdateCallsheetProductionNotesRequestWorker
  )
  yield takeLatest(GET_MEMBER_TITLES_REQUEST, GetMemberTitlesRequestWorker)
  yield takeLatest(PUBLISH_CALLSHEET_REQUEST, PublishCallsheetRequestWorker)
}
