const style = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  label: {
    color: 'text.primary',
    lineHeight: '20px',
    textAlign: 'left',
    mb: 0,
  },
  scheduleDefault: {
    marginTop: 0,
    border: '1px solid #E2E2E2',
    padding: '48px 60px',
    borderRadius: '12px',
    textAlign: 'center',
    lineHeight: '24px',
    fontSize: '16px',
    marginBottom: 4,
  },
  addBtn: {
    lineHeight: '20px',
    fontWeight: 900,
    '> svg': {
      marginLeft: '10px',
      width: '20px',
      path: {
        stroke: 'white',
      },
    },
  },
}

export default style
