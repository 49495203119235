import { call, put, select, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import {
  addContactApi,
  getContactsApi,
  updateContactApi,
} from '../../api/contacts'
import {
  AddContactResultType,
  GetContactsResultType,
  UpdateContactResultType,
  GetContactsTagsResultType,
} from '../../api/contacts/types'
import { getContactsTagsApi } from '../../api/tags'
import { SampleError } from '../../api/types'
import {
  setIsAddContactPanel,
  setIsMemberProfilePanel,
} from '../Builder/actions'
import { setNotificationErrorWorker } from '../Notification/sagas'
import { selectUserId } from '../User/selectors'
import {
  getContactsRequestSuccess,
  getContactsRequestError,
  addContactRequestSuccess,
  addContactRequestError,
  updateContactRequestError,
  updateContactRequestSuccess,
  getContactsTagsRequestSuccess,
  getContactsTagsRequestError,
} from './actions'
import {
  ADD_CONTACT_REQUEST,
  GET_CONTACTS_REQUEST,
  UPDATE_CONTACT_REQUEST,
  GET_CONTACTS_TAGS_REQUEST,
} from './actionTypes'
import { AddContactRequestType, UpdateContactRequestType } from './types'

export function* GetContactsRequestWorker() {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetContactsResultType> = yield call(getContactsApi)
    yield put(getContactsRequestSuccess(data))
  } catch (err) {
    yield put(getContactsRequestError(err as SampleError))
  }
}

export function* AddContactRequestWorker({ payload }: AddContactRequestType) {
  const userId: string = yield select(selectUserId)
  try {
    const {
      data: { data },
    }: AxiosResponse<AddContactResultType> = yield call(
      addContactApi,
      userId,
      payload
    )
    yield put(addContactRequestSuccess(data.id))
    yield put(setIsAddContactPanel(false))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(addContactRequestError())
  }
}

export function* UpdateContactRequestWorker({
  payload,
}: UpdateContactRequestType) {
  const userId: string = yield select(selectUserId)
  try {
    const {
      data: { data },
    }: AxiosResponse<UpdateContactResultType> = yield call(
      updateContactApi,
      userId,
      payload
    )
    yield put(updateContactRequestSuccess(data.id))
    yield put(setIsMemberProfilePanel(false))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(updateContactRequestError())
  }
}

export function* GetContactsTagsRequestWorker() {
  const userId: string = yield select(selectUserId)

  try {
    const {
      data: { data },
    }: AxiosResponse<GetContactsTagsResultType> = yield call(
      getContactsTagsApi,
      userId
    )
    yield put(getContactsTagsRequestSuccess(data))
  } catch (err) {
    yield put(getContactsTagsRequestError(err as SampleError))
  }
}

export function* requestContactsWatcher(): Generator {
  yield takeLatest(GET_CONTACTS_REQUEST, GetContactsRequestWorker)
  yield takeLatest(ADD_CONTACT_REQUEST, AddContactRequestWorker)
  yield takeLatest(UPDATE_CONTACT_REQUEST, UpdateContactRequestWorker)
  yield takeLatest(GET_CONTACTS_TAGS_REQUEST, GetContactsTagsRequestWorker)
}
