import { Box, Stack } from '@mui/material'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setIsAddContactPanel,
  setIsGroupMembersPanel,
  setIsPersistentPanel,
} from '../../../../store/Builder/actions'
import { updateGroupMembers } from '../../../../store/GroupMembers/actions'
import { selectIsAddContactPanel } from '../../../../store/Builder/selectors'
import { selectGroupsRequestUpdateStatus } from '../../../../store/Groups/selectors'

import AddContactForm from '../../ProjectOverview/MembersForm/AddContactForm/AddContactForm'
import ControlButtons from '../../../../components/ControlButtons'
import GroupMembersBox from './GroupMembersBox'
import SidePanel from '../../../../components/SidePanel'
import HeadText from '../../../../components/HeadText'

import style from './style'

const AddGroupMemberForm: FC = () => {
  const isAddContactPanel = useSelector(selectIsAddContactPanel)
  const requestUpdateStatus = useSelector(selectGroupsRequestUpdateStatus)
  const dispatch = useDispatch()

  const handleCancelBtn = () => {
    dispatch(updateGroupMembers(true))
    dispatch(setIsGroupMembersPanel(false))
    dispatch(setIsPersistentPanel(false))
  }

  const handleConfirmBtn = () => {
    dispatch(updateGroupMembers())
    dispatch(setIsGroupMembersPanel(false))
    dispatch(setIsPersistentPanel(false))
  }

  const handleAddContactClose = () => {
    dispatch(setIsAddContactPanel(false))
  }

  return (
    <>
      <Stack sx={style.wrapper}>
        <Box sx={style.content}>
          <HeadText
            title='Add members to the groups'
            subtitle='Please, add at least one contact to each group or create a new one if it’s need'
          />
          <GroupMembersBox />
        </Box>
        <Box sx={style.btnContainer}>
          <ControlButtons
            confirmTitle='Save and Close'
            handleCancelBtn={handleCancelBtn}
            handleConfirmBtn={handleConfirmBtn}
            requestStatus={requestUpdateStatus}
          />
        </Box>
      </Stack>
      <SidePanel isOpen={isAddContactPanel} onClose={handleAddContactClose}>
        <AddContactForm />
      </SidePanel>
    </>
  )
}

export default AddGroupMemberForm
