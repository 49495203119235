const style = {
  wrapper: { justifyContent: 'center', alignItems: 'center' },
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
    border: ' 1px solid #E2E2E2',
    borderRadius: '8px',
    width: '100%',
    py: 0,
    px: 1,
  },
  avatar_wrapper: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  icon_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '.clickable': {
      cursor: 'pointer',
    },
  },
  email_phone: {
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
}

export default style
