import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'
import {
  CheckCircleGreenIcon,
  CrossIcon,
  HelpCircleOutlineIcon,
} from '../../assets/icons'

import style from './style'

const statuses = {
  decline: {
    title: 'Decline',
    icon: <CrossIcon />,
    color: '#CC2E26',
  },
  tentative: {
    title: 'Tentative',
    icon: <HelpCircleOutlineIcon />,
    color: '#FA6400',
  },
  accept: {
    title: 'Accept',
    icon: <CheckCircleGreenIcon />,
    color: '#2C9A5A',
  },
}

const InvitationStatusBox: FC<{
  status: keyof typeof statuses
  withIcon?: boolean
  displayNumber?: string
  round?: boolean
}> = ({ status, withIcon = false, displayNumber = '', round = false }) => {
  return (
    <Box sx={style.statusWrapper(statuses[status].color, round)}>
      <Typography sx={style.status(statuses[status].color)}>
        {' '}
        {displayNumber}
      </Typography>
      <Typography sx={style.status(statuses[status].color)}>
        {statuses[status].title}
      </Typography>
      {withIcon && statuses[status].icon}
    </Box>
  )
}

export default InvitationStatusBox
