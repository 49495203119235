import React, { FC, PropsWithChildren } from 'react'
import { Link } from '@mui/material'
import style from './style'

const getFormattedLinkByType = (link: string, type: string): string =>
  type === 'email' ? `mailto:${link}` : link

type SocialLinkProps = {
  link?: string
  type?: 'website' | 'email'
}

const SocialLink: FC<SocialLinkProps & PropsWithChildren> = ({
  link,
  children,
  type = 'website',
}) => {
  return (
    <>
      {link ? (
        <Link
          href={link && getFormattedLinkByType(link, type)}
          underline='none'
          sx={style.root}
          title={link}
          target='_blank'
        >
          {children}
        </Link>
      ) : (
        <Link
          component='button'
          sx={style.root}
          disabled
          href={link && getFormattedLinkByType(link, type)}
        >
          {children}
        </Link>
      )}
    </>
  )
}

export default SocialLink
