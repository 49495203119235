const PREFIX = 'CALLSHEET/'
export const GET_CALLSHEETS_REQUEST = `${PREFIX}GET_CALLSHEETS_REQUEST` as const
export const GET_CALLSHEETS_REQUEST_SUCCESS =
  `${PREFIX}GET_CALLSHEETS_REQUEST_SUCCESS` as const
export const GET_CALLSHEETS_REQUEST_ERROR =
  `${PREFIX}GET_CALLSHEETS_REQUEST_ERROR` as const
export const GET_CALLSHEET_BY_ID_REQUEST =
  `${PREFIX}GET_CALLSHEET_BY_ID_REQUEST` as const
export const GET_CALLSHEET_BY_ID_REQUEST_SUCCESS =
  `${PREFIX}GET_CALLSHEET_BY_ID_REQUEST_SUCCESS` as const
export const GET_CALLSHEET_BY_ID_REQUEST_ERROR =
  `${PREFIX}GET_CALLSHEET_BY_ID_REQUEST_ERROR` as const
export const SAVE_CALLSHEET_REQUEST = `${PREFIX}SAVE_CALLSHEET_REQUEST` as const
export const SAVE_CALLSHEET_REQUEST_ERROR =
  `${PREFIX}SAVE_CALLSHEET_REQUEST_ERROR` as const
export const SAVE_CALLSHEET_REQUEST_SUCCESS =
  `${PREFIX}SAVE_CALLSHEET_REQUEST_SUCCESS` as const
export const GET_MEMBER_TITLES_REQUEST =
  `${PREFIX}GET_MEMBER_TITLES_REQUEST` as const
export const GET_MEMBER_TITLES_REQUEST_SUCCESS =
  `${PREFIX}GET_MEMBER_TITLES_REQUEST_SUCCESS` as const
export const GET_MEMBER_TITLES_REQUEST_ERROR =
  `${PREFIX}GET_MEMBER_TITLES_REQUEST_ERROR` as const
export const UPDATE_CALLSHEET_REQUEST =
  `${PREFIX}UPDATE_CALLSHEET_REQUEST` as const
export const UPDATE_CALLSHEET_REQUEST_SUCCESS =
  `${PREFIX}UPDATE_CALLSHEET_REQUEST_SUCCESS` as const
export const UPDATE_CALLSHEET_REQUEST_ERROR =
  `${PREFIX}UPDATE_CALLSHEET_REQUEST_ERROR` as const
export const SET_CALLSHEET = `${PREFIX}SET_CALLSHEET` as const
export const SET_CALLSHEET_ID = `${PREFIX}SET_CALLSHEET_ID` as const
export const SET_CALLSHEET_TITLE = `${PREFIX}SET_CALLSHEET_TITLE` as const
export const SET_IS_COMBINED_NOTES = `${PREFIX}SET_IS_COMBINED_NOTES` as const
export const SET_EVENT_DATE = `${PREFIX}SET_EVENT_DATE` as const
export const SET_CREW_CALL_TIME = `${PREFIX}SET_CREW_CALL_TIME` as const
export const SET_PRODUCT_COMPANY_ID = `${PREFIX}SET_PRODUCT_COMPANY_ID` as const
export const SET_CLIENT_COMPANY_ID = `${PREFIX}SET_CLIENT_COMPANY_ID` as const

export const SET_PRODUCTION_NOTE_DETAILS =
  `${PREFIX}SET_PRODUCTION_NOTE_DETAILS` as const
export const SET_PRODUCTION_NOTE_TITLE =
  `${PREFIX}SET_PRODUCTION_NOTE_TITLE` as const

export const SAVE_PRODUCTION_NOTES_REQUEST =
  `${PREFIX}SAVE_PRODUCTION_NOTE_DETAILS_REQUEST` as const

export const PUBLISH_CALLSHEET_REQUEST =
  `${PREFIX}PUBLISH_CALLSHEET_REQUEST` as const
export const PUBLISH_CALLSHEET_REQUEST_SUCCESS =
  `${PREFIX}PUBLISH_CALLSHEET_REQUEST_SUCCESS` as const
