import React, { FC, HTMLAttributes, JSXElementConstructor } from 'react'
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete'
import { PopperProps } from '@mui/material/Popper'
import { noop } from 'lodash'
import { Box, CircularProgress, InputLabel, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { RequestStatuses } from '../../api/constants'
import style from './style'

type SearchInputType = {
  id?: string
  value: any
  placeholder: string
  onInputChange: typeof noop
  error?: boolean
  required?: boolean
  label: string
  options: any[]
  requestStatus: string
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode
  getOptionLabel?: (option: any) => string
  PaperComponent?: JSXElementConstructor<HTMLAttributes<HTMLElement>>
  PopperComponent?: JSXElementConstructor<PopperProps>
  labelStyles?: { [key: string]: number | string }
}

const SearchField: FC<SearchInputType> = ({
  id,
  value = '',
  placeholder,
  onInputChange,
  error = false,
  required = false,
  label,
  options,
  requestStatus,
  renderOption,
  getOptionLabel = (option: string) => option,
  PaperComponent,
  PopperComponent,
  labelStyles = {},
}) => {
  return (
    <Box>
      <InputLabel htmlFor={id} required={required} sx={labelStyles}>
        {label}
      </InputLabel>
      <Autocomplete
        value={value}
        sx={style.autocomplete}
        clearOnBlur={false}
        id={id}
        options={options}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        PaperComponent={PaperComponent}
        PopperComponent={PopperComponent}
        renderInput={params => (
          <TextField
            {...params}
            id={id}
            error={error}
            placeholder={placeholder}
            onBlur={onInputChange}
            onChange={onInputChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {requestStatus === RequestStatuses.PENDING ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  )
}

export default SearchField
