const style = {
  input: {
    borderRadius: '4px',
    height: '32px',
    fontSize: '14px',
    color: 'primary.light',
    input: {
      textAlign: 'center',
      cursor: 'pointer',
      '&::placeholder': {
        color: 'text.primary',
      },
    },
  },
}

export default style
