import { AppState } from '../types'

export const selectRequestStatus = (state: AppState) =>
  state.callsheetState.requestStatus
export const selectCallsheetsRequestStatus = (state: AppState) =>
  state.callsheetState.callsheetsRequestStatus
export const selectMembersTitlesRequestStatus = (state: AppState) =>
  state.callsheetState.memberTitlesRequestStatus
export const selectCallsheets = (state: AppState) =>
  state.callsheetState.callsheets
export const selectMembersTitles = (state: AppState) =>
  state.callsheetState.memberTitles
export const selectCallsheetTitle = (state: AppState) =>
  state.callsheetState.callsheet.title
export const selectEventDate = (state: AppState) =>
  state.callsheetState.callsheet.eventDate
export const selectCrewCallTime = (state: AppState) =>
  state.callsheetState.callsheet.crewCallTime
export const selectProductCompanyId = (state: AppState) =>
  state.callsheetState.callsheet.productCompanyId
export const selectClientCompanyId = (state: AppState) =>
  state.callsheetState.callsheet.clientCompanyId
export const selectCallsheetId = (state: AppState) =>
  state.callsheetState.callsheet.id
export const selectIsCombinedNotes = (state: AppState) =>
  state.callsheetState.callsheet.combinedNotes
export const selectCalsheetData = (state: AppState) =>
  state.callsheetState.callsheet

export const selectCalsheetProductionNotes = (state: AppState) =>
  state.callsheetState.callsheet.productionNoteDetails
export const selectCalsheetProductionNotesTitle = (state: AppState) =>
  state.callsheetState.callsheet.productionNoteTitle
