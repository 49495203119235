import { AxiosPromise } from 'axios'
import { METHODS, URLS } from '../constants'
import { apiClient } from '../clients'
import { WalkieChannel } from '../../store/WalkieChannels/types'

export const getWalkieChannelsApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${callsheetId}/walkie-channel`,
  })

export const addWalkieChannelsApi = (
  callsheetId: string,
  name: string,
  channel: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/walkie-channel`,
    data: {
      callsheetId,
      name,
      channel,
    },
  })

export const deleteWalkieChannelsApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.DELETE,
    url: `${URLS.CALLSHEET}/walkie-channel/${id}`,
  })

export const updateWalkieChannelsApi = (
  id: string,
  name: string,
  channel: string
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/walkie-channel/${id}`,
    data: {
      name,
      channel,
    },
  })
