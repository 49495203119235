import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectStep } from '../../store/Builder/selectors'
import {
  getCallsheetRequest,
  setCallsheetId,
} from '../../store/Callsheet/actions'
import { selectCallsheetId } from '../../store/Callsheet/selectors'
import { getGroupsRequest } from '../../store/Groups/actions'
import ManageUserGroups from './ManageUserGroups'

import ProjectOverview from './ProjectOverview'
import BuilderLayout from '../../components/BuilderLayout'

import { STEPS } from './constants'
import FinalTouches from './FinalTouches'
import PublishAndSend from './PublishAndSend'

const BuilderPage: FC = () => {
  const callsheetId = useSelector(selectCallsheetId)
  const step = useSelector(selectStep)
  const dispatch = useDispatch()

  useEffect(() => {
    if (callsheetId) {
      dispatch(getCallsheetRequest(callsheetId))
      sessionStorage.setItem('callsheetId', callsheetId)
    }
    if (callsheetId && step === STEPS.userGroups) {
      dispatch(getGroupsRequest())
    }
    const callsheet = sessionStorage.getItem('callsheetId')
    if (callsheet) {
      dispatch(setCallsheetId(callsheet))
    }
  }, [callsheetId])

  return (
    <BuilderLayout>
      {step === STEPS.overview || step === STEPS.logistic ? (
        <ProjectOverview />
      ) : null}
      {step === STEPS.userGroups ? <ManageUserGroups /> : null}
      {step === STEPS.finalTouches ? <FinalTouches /> : null}
      {step === STEPS.publishAndSend ? <PublishAndSend /> : null}
    </BuilderLayout>
  )
}

export default BuilderPage
