import React from 'react'
import { Box, InputAdornment, InputBase, Typography } from '@mui/material'
import style from './style'

const SelectFilterInput = (props: JSX.IntrinsicAttributes) => {
  return (
    <Box display='flex' alignItems='center'>
      <InputBase
        {...props}
        sx={style.input}
        startAdornment={
          <InputAdornment position='start'>
            <Typography variant='body2' color='primary.main' sx={style.label}>
              Filter:
            </Typography>
          </InputAdornment>
        }
      />
    </Box>
  )
}

export default SelectFilterInput
