import { combineReducers } from 'redux'
import builderReducer from './Builder/reducer'
import { CLEAR_STORE } from './rootActions'
import signInReducer from './SignIn/reducer'
import userReducer from './User/reducer'
import callsheetReducer from './Callsheet/reducer'
import companiesReducer from './Companies/reducer'
import contactsReducer from './Contacts/reducer'
import logisticsReducer from './Logistics/reducer'
import groupsReducer from './Groups/reducer'
import notificationReducer from './Notification/reducer'
import groupMembersReducer from './GroupMembers/reducer'
import announcementsReducer from './Announcements/reducer'
import walkieChannelsReducer from './WalkieChannels/reducer'
import eventsReducer from './Events/reducer'
import smsParametersReducer from './SmsParameters/reducer'
import emailParametersReducer from './EmailParameters/reducer'
import publishDetailsReducer from './PublishDetails/reducer'

const appReducer = combineReducers({
  signInState: signInReducer,
  builderState: builderReducer,
  userState: userReducer,
  callsheetState: callsheetReducer,
  companiesState: companiesReducer,
  contactsState: contactsReducer,
  logisticsState: logisticsReducer,
  groupsState: groupsReducer,
  groupMembersState: groupMembersReducer,
  notificationState: notificationReducer,
  announcementsState: announcementsReducer,
  walkieChannelsState: walkieChannelsReducer,
  eventsState: eventsReducer,
  smsParametersState: smsParametersReducer,
  emailParametersState: emailParametersReducer,
  publishDetailsState: publishDetailsReducer,
})

// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
