import { SampleError } from '../../api/types'
import {
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_REQUEST_ERROR,
  ADD_CONTACT_REQUEST_SUCCESS,
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_REQUEST_ERROR,
  GET_CONTACTS_REQUEST_SUCCESS,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_REQUEST_SUCCESS,
  UPDATE_CONTACT_REQUEST_ERROR,
  GET_CONTACTS_TAGS_REQUEST,
  GET_CONTACTS_TAGS_REQUEST_ERROR,
  GET_CONTACTS_TAGS_REQUEST_SUCCESS,
  SET_CONTACTS,
} from './actionTypes'
import {
  Contact,
  ContactTag,
  SetContacts,
  GetContactsRequestType,
  GetContactsRequestSuccessType,
  GetContactsRequestErrorType,
  AddContactRequestType,
  AddContactRequestErrorType,
  AddContactRequestSuccessType,
  UpdateContactRequestType,
  UpdateContactRequestSuccessType,
  UpdateContactRequestErrorType,
  GetContactsTagsRequestType,
  GetContactsTagsRequestSuccessType,
  GetContactsTagsRequestErrorType,
} from './types'

export const getContactsRequest = (): GetContactsRequestType => ({
  type: GET_CONTACTS_REQUEST,
})

export const getContactsRequestSuccess = (
  contacts: Contact[]
): GetContactsRequestSuccessType => ({
  type: GET_CONTACTS_REQUEST_SUCCESS,
  contacts,
})

export const getContactsRequestError = (
  error: SampleError
): GetContactsRequestErrorType => ({
  type: GET_CONTACTS_REQUEST_ERROR,
  error,
})

export const addContactRequest = (
  contact: Omit<Contact, 'id'>
): AddContactRequestType => ({
  type: ADD_CONTACT_REQUEST,
  payload: contact,
})

export const addContactRequestSuccess = (
  id?: string
): AddContactRequestSuccessType => ({
  type: ADD_CONTACT_REQUEST_SUCCESS,
  id,
})

export const addContactRequestError = (): AddContactRequestErrorType => ({
  type: ADD_CONTACT_REQUEST_ERROR,
})

export const updateContactRequest = (
  contact: Contact
): UpdateContactRequestType => ({
  type: UPDATE_CONTACT_REQUEST,
  payload: contact,
})

export const updateContactRequestSuccess = (
  id: string
): UpdateContactRequestSuccessType => ({
  type: UPDATE_CONTACT_REQUEST_SUCCESS,
  id,
})

export const updateContactRequestError = (): UpdateContactRequestErrorType => ({
  type: UPDATE_CONTACT_REQUEST_ERROR,
})

export const getContactsTagsRequest = (): GetContactsTagsRequestType => ({
  type: GET_CONTACTS_TAGS_REQUEST,
})

export const getContactsTagsRequestSuccess = (
  tags: ContactTag[]
): GetContactsTagsRequestSuccessType => ({
  type: GET_CONTACTS_TAGS_REQUEST_SUCCESS,
  tags,
})

export const getContactsTagsRequestError = (
  error: SampleError
): GetContactsTagsRequestErrorType => ({
  type: GET_CONTACTS_TAGS_REQUEST_ERROR,
  error,
})

export const setContacts = (contacts: Contact[]): SetContacts => ({
  type: SET_CONTACTS,
  contacts,
})
