import { AppState } from '../types'

export const selectCompanyType = (state: AppState) =>
  state.builderState.companyType
export const selectStep = (state: AppState) => state.builderState.step
export const selectIsPersistentPanel = (state: AppState) =>
  state.builderState.isPersistentPanel
export const selectIsCompanyPanel = (state: AppState) =>
  state.builderState.isCompanyPanel
export const selectIsMembersPanel = (state: AppState) =>
  state.builderState.isMembersPanel
export const selectIsMemberProfilePanel = (state: AppState) =>
  state.builderState.isMemberProfilePanel
export const selectIsAddContactPanel = (state: AppState) =>
  state.builderState.isAddContactPanel
export const selectIsLocationPanel = (state: AppState) =>
  state.builderState.isLocationPanel
export const selectIsUserGroupPanel = (state: AppState) =>
  state.builderState.isUserGroupPanel
export const selectIsGroupMembersPanel = (state: AppState) =>
  state.builderState.isGroupMembersPanel
