import React from 'react'
import { useSelector } from 'react-redux'
import { Box, StepLabel, Step, Stepper } from '@mui/material'

import { selectStep } from '../../store/Builder/selectors'
import NavigationButtons from '../NavigationButtons'

import Connector from './Connector'

import style from './style'
import CustomStepIcon from './CustomStepIcon'

const steps = [
  'Project overview',
  '',
  'Team/Invites',
  'Final Touches',
  'Publish & Sending',
]

export default function ProgressBar() {
  const step = useSelector(selectStep)

  return (
    <Box sx={style.container}>
      <NavigationButtons>
        <Box sx={style.progressBar}>
          <Stepper
            activeStep={step}
            alternativeLabel
            connector={<Connector />}
            sx={style.stepper}
          >
            {steps.map(label => (
              <Step key={label} sx={style.step}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </NavigationButtons>
    </Box>
  )
}
