import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import SideMenu from 'src/components/SideMenu'
import BuilderPage from '../pages/BuilderPage'
import CallsheetsPage from '../pages/CallsheetsPage'
import { PATHS } from '../constants'
import CallsheetViewPage from '../pages/CallsheetViewPage'

import NotFoundPage from '../pages/NotFoundPage'
import SuccessPage from '../pages/SuccessPage'

const PrivateRouter: FC = () => (
  <Switch>
    <Route exact path={PATHS.PRIVATE.HOME}>
      <Redirect to={PATHS.PRIVATE.CALLSHEETS} />
    </Route>
    <Route path={PATHS.PRIVATE.DASHBOARD}>
      <SideMenu>
        <div>Dashboard</div>
      </SideMenu>
    </Route>
    <Route path={PATHS.PRIVATE.ACTIVITY}>
      <SideMenu>
        <div>Activity</div>
      </SideMenu>
    </Route>
    <Route path={PATHS.PRIVATE.CALLSHEETS}>
      <SideMenu>
        <CallsheetsPage />
      </SideMenu>
    </Route>
    <Route path={PATHS.PRIVATE.NETWORK}>
      <SideMenu>
        <div>network</div>
      </SideMenu>
    </Route>
    <Route path={PATHS.PRIVATE.BUILDER}>
      <BuilderPage />
    </Route>
    <Route path={PATHS.PRIVATE.SUCCESS}>
      <SuccessPage />
    </Route>
    <Route path={PATHS.PRIVATE.CALLSHEET_VIEW}>
      <SideMenu>
        <CallsheetViewPage />
      </SideMenu>
    </Route>
    <Route path='*'>
      <NotFoundPage />
    </Route>
  </Switch>
)

export default PrivateRouter
