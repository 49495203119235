const PREFIX = 'COMPANY/'

export const PREFILL_COMPANY = `${PREFIX}PREFILL_COMPANY` as const
export const SET_COMPANY_ID = `${PREFIX}SET_COMPANY_ID` as const
export const SET_COMPANY_NAME = `${PREFIX}SET_COMPANY_NAME` as const
export const SET_COMPANY_LOGO = `${PREFIX}SET_COMPANY_LOGO` as const
export const SET_COMPANY_WEBSITE = `${PREFIX}SET_COMPANY_WEBSITE` as const
export const SET_COMPANY_ADDRESS = `${PREFIX}SET_COMPANY_ADDRESS` as const
export const SET_WEBSITE_VISIBLE = `${PREFIX}SET_WEBSITE_VISIBLE` as const
export const SET_ADDRESS_VISIBLE = `${PREFIX}SET_ADDRESS_VISIBLE` as const
export const SET_SAVE_IN_CATALOG = `${PREFIX}SET_SAVE_IN_CATALOG` as const
export const SET_MEMBERS = `${PREFIX}SET_MEMBERS` as const
