import React, { FC } from 'react'
import { Box, Card, Divider, Typography } from '@mui/material'
import { ImageType } from 'react-images-uploading/dist/typings'
import { MapPinIcon } from '../../../../../assets/icons'
import style from './style'

type AddressCardType = {
  name?: string
  title?: string
  address?: string
  description?: string
  image?: string
}

const AddressCard: FC<AddressCardType> = ({
  name,
  title,
  address,
  description,
  image,
}) => (
  <Card sx={style.addressBox}>
    <Box sx={style.header}>
      {title ? (
        <Typography variant='subtitle1'>{title}</Typography>
      ) : (
        <Box sx={style.titlePlaceholder} />
      )}
      <Box display='flex' alignItems='center'>
        {image ? (
          <Typography variant='body2' color='primary.main' sx={{ mr: 0 }}>
            Sitemap
          </Typography>
        ) : null}
        <MapPinIcon />
      </Box>
    </Box>
    <Divider sx={{ my: '12px' }} />
    <Box>
      <Typography sx={style.label}>Address:</Typography>
      {address ? (
        <Box>
          <Typography variant='body2' component='div' sx={style.address}>
            {name}
          </Typography>
          <Typography component='div' sx={style.address}>
            {address}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box sx={style.addressPlaceholder} />
          <Box sx={style.addressPlaceholder} />
        </Box>
      )}
    </Box>
    <Box sx={{ mt: '14px' }}>
      <Typography sx={style.label}>Description:</Typography>
      {description ? (
        <Typography component='div' sx={style.description}>
          {description}
        </Typography>
      ) : (
        <Box sx={style.addressPlaceholder} />
      )}
    </Box>
  </Card>
)

export default AddressCard
