import { noop } from 'lodash'
import React, { FC, useCallback } from 'react'

import {
  Box,
  Button,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
} from '@mui/material'

import { SelectChangeEvent } from '@mui/material/Select'

import { DEFAULT_TAG, MenuProps } from './constants'

import SelectFilterInput from './SelectFilterInput'
import { CaretBottomIcon } from '../../../assets/icons'
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox'

import style from './style'

type FilterProps = {
  label: string
  selectedTags: string[]
  tags?: string[]
  setSelectedTags: typeof noop
  defaultTag?: string
}

const CallsheetFilter: FC<FilterProps> = ({
  label,
  selectedTags,
  setSelectedTags,
  defaultTag = DEFAULT_TAG,
  tags = [],
}) => {
  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event
    setSelectedTags(typeof value === 'string' ? value.split(',') : value)
  }

  const handleShowAll = useCallback(() => {
    setSelectedTags([])
  }, [])

  const renderValue = (selected: string[]) => {
    return selectedTags.length === 0 ? defaultTag : selected.join(', ')
  }

  return (
    <div>
      <FormControl>
        <Select
          id='filter-multiple-checkbox'
          multiple
          displayEmpty
          value={selectedTags}
          onChange={handleChange}
          MenuProps={MenuProps}
          input={<SelectFilterInput label={label} />}
          renderValue={renderValue}
          IconComponent={props => (
            <Box {...props} sx={style.icon}>
              <CaretBottomIcon />
            </Box>
          )}
        >
          {[
            <Button
              key='show-all'
              sx={style.button}
              onClick={handleShowAll}
              disableRipple
            >
              {defaultTag}
            </Button>,
            ...tags.map(tag => (
              <MenuItem key={tag} value={tag} sx={style.item}>
                <CustomCheckbox checked={selectedTags.indexOf(tag) > -1} />
                <ListItemText
                  primary={
                    <span>
                      {label} by <b style={style.text}>{tag}</b>
                    </span>
                  }
                />
              </MenuItem>
            )),
          ]}
        </Select>
      </FormControl>
    </div>
  )
}

export default CallsheetFilter
