import { useDispatch } from 'react-redux'
import React, { FC } from 'react'
import { Box, Chip, Divider, Paper, Stack, Typography } from '@mui/material'

import { useToggle } from '../../../../../hooks/useToggle'
import { GroupMember } from '../../../../../store/GroupMembers/types'
import {
  getMembersRequest,
  updateMemberRequest,
} from '../../../../../store/GroupMembers/actions'

import AvatarBox from '../../../../../components/AvatarBox'
import SidePanel from '../../../../../components/SidePanel'
import RecipientProfile from '../RecipientProfile'

import { EditIcon } from '../../../../../assets/icons'

import style from './style'

type RecipientProps = {
  member: GroupMember
  isMissingPhone?: boolean
}

const RecipientListItem: FC<RecipientProps> = ({
  member,
  isMissingPhone = false,
}) => {
  const dispatch = useDispatch()
  const [isOpen, toggleProfile] = useToggle(false)
  const { firstName, title, groupTitle } = member

  const handleEdit = () => {
    toggleProfile()
  }

  const handleRecipientUpdate = (item: GroupMember) => {
    dispatch(updateMemberRequest(item))
    dispatch(getMembersRequest())
    toggleProfile()
  }
  return (
    <>
      <Paper variant='outlined' sx={style.wrapper}>
        <Box sx={style.avatarWrapper}>
          <AvatarBox />
          <Stack alignItems='flex-start' onClick={toggleProfile}>
            <Typography variant='body2' color='text.primary'>
              {firstName}
            </Typography>
            <Typography variant='body1' color='text.disabled'>
              {title}
            </Typography>
          </Stack>
        </Box>
        <Box sx={style.editContainer}>
          <Typography variant='body2' color='text.disabled' marginRight={0}>
            {groupTitle}
          </Typography>
          {isMissingPhone && (
            <>
              <Chip label='Missing Phone' sx={style.missingPhoneChip} />
              <Divider orientation='vertical' flexItem />
              <EditIcon onClick={handleEdit} />
            </>
          )}
        </Box>
      </Paper>
      <SidePanel isOpen={isOpen} onClose={toggleProfile}>
        <RecipientProfile
          onBackClick={toggleProfile}
          onConfirm={isMissingPhone ? handleRecipientUpdate : toggleProfile}
          memberDetails={member}
        />
      </SidePanel>
    </>
  )
}

export default RecipientListItem
