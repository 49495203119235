export const gridsLong = [
  { xs: 2.35, label: 'Name and Position' },
  { xs: 1.9, label: 'Phone Number' },
  { xs: 1.9, label: 'Email' },
  { xs: 1.9, label: 'Call Time' },
  { xs: 1.85, label: 'Wrap Time' },
  { xs: 1, label: 'Notes' },
]
export const gridsShort = [
  { xs: 4, label: 'Name and Position' },
  { xs: 3, label: 'Email' },
  { xs: 2.75, label: 'Call Time' },
  { xs: 2.25, label: 'Wrap Time' },
]
