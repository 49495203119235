import React, { FC } from 'react'
import {
  CallsheetStatuses,
  CallsheetStatusType,
} from '../../../store/Callsheet/types'
import CallsheetCommonFooter from './CallsheetCommonFooter'
import CallsheetCompletedFooter from './CallsheetCompletedFooter'
import CallsheetDeletedFooter from './CallsheetDeletedFooter'
import CallsheetDraftFooter from './CallsheetDraftFooter'
import CallsheetLiveFooter from './CallsheetLiveFooter'
import CallsheetPendingFooter from './CallsheetPendingFooter'
import CallsheetPublishedFooter from './CallsheetPublishedFooter'

type CallsheetCardFooterType = {
  id: string
  status: CallsheetStatusType
}

const CallsheetCardFooter: FC<CallsheetCardFooterType> = ({ id, status }) => {
  switch (status) {
    case CallsheetStatuses.DRAFT:
      return <CallsheetDraftFooter />
    case CallsheetStatuses.LIVE:
      return <CallsheetLiveFooter />
    case CallsheetStatuses.PENDING_INVITE:
      return <CallsheetPendingFooter />
    case CallsheetStatuses.PUBLISHED:
      return <CallsheetPublishedFooter id={id} />
    case CallsheetStatuses.COMPLETED:
      return <CallsheetCompletedFooter id={id} />
    case CallsheetStatuses.DELETED:
      return <CallsheetDeletedFooter />
    case CallsheetStatuses.ACCEPTED:
    case CallsheetStatuses.TENTATIVE:
    case CallsheetStatuses.DECLINED:
      return <CallsheetCommonFooter id={id} />
    default:
      return null
  }
}

export default CallsheetCardFooter
