import { AppState } from '../types'
import { Contact } from './types'

export const selectContacts = (state: AppState) => state.contactsState.contacts
export const selectContactById =
  (id: string | undefined) => (state: AppState) =>
    state.contactsState.contacts.find((contact: Contact) => contact.id === id)
export const selectContactsTags = (state: AppState) => state.contactsState.tags
export const selectContactsRequestStatus = (state: AppState) =>
  state.contactsState.requestStatus
