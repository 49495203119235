import React, { FC } from 'react'
import moment from 'moment'
import { Box, Chip, Divider, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CalendarIcon, OptionsVerticalIcon } from '../../assets/icons'
import { setCallsheetId } from '../../store/Callsheet/actions'
import {
  CallsheetStatuses,
  CallsheetStatusType,
} from '../../store/Callsheet/types'
import OptionsButton from '../OptionsButton/OptionsButton'
import CallsheetCardFooter from './CallsheetCardFooter'
import style from './style'

type CallsheetCardTypes = {
  id: string
  title: string
  eventDate: DateConstructor | null
  crewCallTime: string
  status?: CallsheetStatusType
}

const CallsheetCard: FC<CallsheetCardTypes> = ({
  id,
  title,
  eventDate,
  crewCallTime,
  status = CallsheetStatuses.DRAFT,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isShowAuthor =
    status === CallsheetStatuses.PENDING_INVITE ||
    status === CallsheetStatuses.ACCEPTED ||
    status === CallsheetStatuses.TENTATIVE ||
    status === CallsheetStatuses.DECLINED

  const handleCardClick = () => {
    if (status === CallsheetStatuses.DRAFT) {
      dispatch(setCallsheetId(id))
      history.push('/builder')
    }

    if (
      status === CallsheetStatuses.COMPLETED ||
      status === CallsheetStatuses.ACCEPTED ||
      status === CallsheetStatuses.TENTATIVE
    ) {
      history.push(`/callsheet/${id}`)
    }
  }

  const optionsMenuItems = [
    {
      title: 'Edit & Send',
      hidden: status !== CallsheetStatuses.DRAFT,
      onClick: () => {
        dispatch(setCallsheetId(id))
        history.push('/builder')
      },
    },
    {
      title: 'Statistics',
      disabled: true,
    },
    {
      title: 'Duplicate',
      disabled: true,
    },
    {
      title: 'Delete',
      disabled: true,
    },
  ]

  return (
    <Box sx={style.card(status)}>
      <Box sx={style.optionsBtn}>
        <OptionsButton menuItems={optionsMenuItems}>
          <OptionsVerticalIcon />
        </OptionsButton>
      </Box>
      <Box onClick={handleCardClick}>
        <Box sx={style.header}>
          <Typography sx={style.title}>{title}</Typography>
          {eventDate ? (
            <Box sx={style.dateBox}>
              <CalendarIcon />
              <Typography sx={style.dateText}>
                {moment(eventDate.toString()).format('MMMM, D')} at{' '}
                {crewCallTime}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box sx={style.body}>
          <Chip
            variant='outlined'
            label={status}
            size='small'
            sx={style.status}
          />
          {isShowAuthor ? (
            <Typography sx={style.author}>By: Brooklyn Simons</Typography>
          ) : null}
        </Box>
        <Divider />
        <Box sx={style.footer}>
          <CallsheetCardFooter id={id} status={status} />
        </Box>
      </Box>
    </Box>
  )
}

export default CallsheetCard
