const style = {
  itemWrapper: {
    padding: 1,
  },
  title: {
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
    color: '#163651',
  },
  infoTxt: {
    fontSize: '12px',
    lineHeight: '20px',
  },
}

export default style
