import { Divider, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React, { FC } from 'react'
import StatusChip from 'src/components/CallsheetCard/StatusChip'
import InvitationStatusBox from 'src/components/InvitationStatusBox'
import { CallsheetStatuses } from 'src/store/Callsheet/types'

import style from './style'

type CallsheetListItemProps = {
  title: string
  eventDate: DateConstructor | null
  companyName: string
  status?: CallsheetStatuses
  invitations?: []
  divider?: boolean
}

const CallsheetListItem: FC<CallsheetListItemProps> = ({
  title,
  companyName,
  eventDate,
  status = CallsheetStatuses.DRAFT,
  invitations,
  divider = true,
}) => {
  return (
    <>
      <Grid container sx={style.itemWrapper}>
        <Grid item xs={2.5}>
          <Typography sx={style.title}>{title}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <StatusChip status={status} />
        </Grid>
        <Grid item xs={2}>
          <Typography sx={style.infoTxt}>
            {moment(eventDate?.toString()).format('dddd, MMMM Do')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={style.infoTxt}>{companyName}</Typography>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', gap: 1 }}>
          {invitations ? (
            <>
              <InvitationStatusBox status='decline' displayNumber='24' round />
              <InvitationStatusBox status='accept' displayNumber='4' round />
              <InvitationStatusBox status='tentative' displayNumber='8' round />
            </>
          ) : (
            '-'
          )}
        </Grid>
      </Grid>
      {divider && <Divider variant='middle' />}
    </>
  )
}

export default CallsheetListItem
