import { SampleError } from '../../api/types'
import { Contact } from '../Contacts/types'
import {
  ADD_MEMBER_REQUEST,
  DELETE_MEMBER_REQUEST,
  SAVE_COMPANY_REQUEST,
  SAVE_COMPANY_REQUEST_SUCCESS,
  SAVE_COMPANY_REQUEST_ERROR,
  UPDATE_COMPANY_MEMBERS_REQUEST,
  UPDATE_COMPANY_MEMBERS_REQUEST_SUCCESS,
  UPDATE_COMPANY_MEMBERS_REQUEST_ERROR,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_REQUEST_SUCCESS,
  UPDATE_COMPANY_REQUEST_ERROR,
  GET_COMPANIES_BY_NAME_REQUEST,
  GET_COMPANY_BY_ID_REQUEST,
  GET_COMPANIES_REQUEST_SUCCESS,
  GET_COMPANY_REQUEST_SUCCESS,
  GET_COMPANIES_REQUEST_ERROR,
  SET_COMPANY,
} from './actionTypes'
import {
  AddMemberRequestType,
  DeleteMemberRequestType,
  SaveCompanyRequestType,
  SaveCompanyRequestSuccessType,
  SaveCompanyRequestErrorType,
  UpdateCompanyRequestType,
  UpdateCompanyRequestSuccessType,
  UpdateCompanyRequestErrorType,
  GetCompaniesByNameRequestType,
  GetCompaniesByIdRequestType,
  GetCompaniesRequestErrorType,
  GetCompaniesRequestSuccessType,
  GetCompanyRequestSuccessType,
  UpdateCompanyMembersRequestType,
  UpdateCompanyMembersRequestSuccessType,
  UpdateCompanyMembersRequestErrorType,
  Company,
  SetCompany,
} from './types'

export const saveCompanyRequest = (
  company: Company
): SaveCompanyRequestType => ({
  type: SAVE_COMPANY_REQUEST,
  payload: {
    company,
  },
})

export const saveCompanyRequestSuccess = (
  companyType: string,
  id: string
): SaveCompanyRequestSuccessType => ({
  type: SAVE_COMPANY_REQUEST_SUCCESS,
  companyType,
  id,
})

export const saveCompanyRequestError = (
  error: SampleError
): SaveCompanyRequestErrorType => ({
  type: SAVE_COMPANY_REQUEST_ERROR,
  error,
})

export const updateCompanyRequest = (
  companyId: string,
  company: Company
): UpdateCompanyRequestType => ({
  type: UPDATE_COMPANY_REQUEST,
  payload: {
    companyId,
    company,
  },
})

export const updateCompanyRequestSuccess = (
  companyType: string,
  id: string
): UpdateCompanyRequestSuccessType => ({
  type: UPDATE_COMPANY_REQUEST_SUCCESS,
  companyType,
  id,
})

export const updateCompanyRequestError = (
  error: SampleError
): UpdateCompanyRequestErrorType => ({
  type: UPDATE_COMPANY_REQUEST_ERROR,
  error,
})

export const getCompanyByIdRequest = (
  companyType: string,
  id: string
): GetCompaniesByIdRequestType => ({
  type: GET_COMPANY_BY_ID_REQUEST,
  payload: {
    companyType,
    id,
  },
})

export const getCompaniesByNameRequest = (
  name: string,
  catalogOnly?: boolean
): GetCompaniesByNameRequestType => ({
  type: GET_COMPANIES_BY_NAME_REQUEST,
  payload: {
    name,
    catalogOnly,
  },
})

export const getCompaniesRequestSuccess = (
  companies: Company[]
): GetCompaniesRequestSuccessType => ({
  type: GET_COMPANIES_REQUEST_SUCCESS,
  companies,
})

export const getCompanyRequestSuccess = (
  company: Company,
  companyType: string
): GetCompanyRequestSuccessType => ({
  type: GET_COMPANY_REQUEST_SUCCESS,
  company,
  companyType,
})

export const getCompaniesRequestError = (
  error: SampleError
): GetCompaniesRequestErrorType => ({
  type: GET_COMPANIES_REQUEST_ERROR,
  error,
})

export const addCompanyMemberRequest = (
  companyId: string,
  contactId: string
): AddMemberRequestType => ({
  type: ADD_MEMBER_REQUEST,
  payload: {
    companyId,
    contactId,
  },
})

export const deleteCompanyMemberRequest = (
  companyId: string,
  memberId: string
): DeleteMemberRequestType => ({
  type: DELETE_MEMBER_REQUEST,
  payload: {
    companyId,
    memberId,
  },
})

export const updateCompanyMembersRequest = (
  companyId: string,
  deletedMembers: Contact[],
  addedMembers: Contact[]
): UpdateCompanyMembersRequestType => ({
  type: UPDATE_COMPANY_MEMBERS_REQUEST,
  payload: {
    companyId,
    deletedMembers,
    addedMembers,
  },
})

export const updateCompanyMembersRequestSuccess =
  (): UpdateCompanyMembersRequestSuccessType => ({
    type: UPDATE_COMPANY_MEMBERS_REQUEST_SUCCESS,
  })

export const updateCompanyMembersRequestError =
  (): UpdateCompanyMembersRequestErrorType => ({
    type: UPDATE_COMPANY_MEMBERS_REQUEST_ERROR,
  })

export const setCompany = (
  companyType: string,
  company: Company | Record<string, unknown>
): SetCompany => ({
  type: SET_COMPANY,
  companyType,
  company,
})
