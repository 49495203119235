import { Box, Button, Typography } from '@mui/material'
import { noop } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import React, { FC } from 'react'
import { useToggle } from '../../hooks/useToggle'
import { clearStore } from '../../store/rootActions'
import { getUserById } from '../../store/User/actions'
import { getTokensFromStorage } from '../../utils/tokens'

import CallsheetFilter from './CallsheetFilter'

import { PlusWhiteIcon } from '../../assets/icons'
import { PATHS } from '../../constants'

import style from './style'

const CallsheetsFilterSection: FC<{
  isGalleryView: boolean
  onToggle: typeof noop
}> = ({ isGalleryView, onToggle }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleCreateCallsheet = () => {
    dispatch(clearStore())
    const { token } = getTokensFromStorage()
    if (token) {
      dispatch(getUserById(token))
    }

    history.push(PATHS.PRIVATE.BUILDER)
  }

  return (
    <Box sx={style.wrapper}>
      <Box sx={style.viewToggle}>
        <Typography sx={style.toggleLabel(isGalleryView)} onClick={onToggle}>
          Gallery View
        </Typography>
        <Typography sx={style.toggleLabel(!isGalleryView)} onClick={onToggle}>
          List View
        </Typography>
      </Box>
      <Box display='flex' alignItems='center' gap={1}>
        <CallsheetFilter
          selectedTags={[]}
          setSelectedTags={() => {}}
          label='Filter'
        />
        <CallsheetFilter
          selectedTags={['Date Ascending']}
          defaultTag='Date Ascending'
          setSelectedTags={() => {}}
          label='Sort'
        />
        <Button
          color='info'
          variant='contained'
          sx={style.builderBtn}
          onClick={handleCreateCallsheet}
        >
          <Box sx={style.builderBtnLarge}>
            <PlusWhiteIcon />
          </Box>
          <Typography variant='subtitle2' sx={style.builderBtnText}>
            Create Callsheet
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}
export default CallsheetsFilterSection
