import React, { FC } from 'react'

import { noop } from 'lodash'
import moment from 'moment'
import { Select, MenuItem, Typography } from '@mui/material'
import style from './style'

type TimePickerPropTypes = {
  value?: string
  onChange: typeof noop
  name?: string
  beginLimit?: string
  endLimit?: string
  step?: string
  placeholder?: string
}

const TimePickerDropdown: FC<TimePickerPropTypes> = ({
  step = 15,
  endLimit = '11:45pm',
  beginLimit = '12:00am',
  name = 'time picker',
  value,
  onChange = noop,
  placeholder,
}) => {
  const isEarlierThanEndLimit = (
    timeValue: string,
    endLim: string,
    lastValue?: string
  ) => {
    const timeValueIsEarlier =
      moment(timeValue, 'h:mma').diff(moment(endLim, 'h:mma')) < 0
    const timeValueIsLaterThanLastValue =
      lastValue === undefined
        ? true
        : moment(lastValue, 'h:mma').diff(moment(timeValue, 'h:mma')) < 0
    return timeValueIsEarlier && timeValueIsLaterThanLastValue
  }

  let timeValue = beginLimit
  let lastValue

  const options = []
  options.push(
    <MenuItem key={timeValue} value={timeValue} sx={style.menuInput}>
      {timeValue}
    </MenuItem>
  )

  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
    lastValue = timeValue

    timeValue = moment(timeValue, 'h:mma').add(step, 'minutes').format('h:mma')
    options.push(
      <MenuItem key={timeValue} value={timeValue} sx={style.menuInput}>
        {timeValue}
      </MenuItem>
    )
  }

  return (
    <Select
      value={value || placeholder}
      renderValue={() => value || placeholder}
      displayEmpty
      onChange={onChange}
      name={name}
      size='small'
      IconComponent={() => <div style={{ display: 'none' }} />}
      MenuProps={{ sx: { height: '240px' } }}
      sx={style.select}
    >
      {placeholder ? (
        <MenuItem disabled value={placeholder} sx={{ display: 'none' }}>
          <Typography
            variant='body1'
            color='text.disabled'
            sx={{ fontSize: '14px' }}
          >
            {placeholder}
          </Typography>
        </MenuItem>
      ) : null}
      {options}
    </Select>
  )
}

export default TimePickerDropdown
