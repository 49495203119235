const style = {
  addressBox: {
    width: '100%',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    p: '14px',
    border: '1px solid #E2E2E2',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.06)',
  },
  address: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titlePlaceholder: {
    width: '136px',
    height: '24px',
    backgroundColor: '#D3DEE7',
    borderRadius: '8px',
  },
  addressPlaceholder: {
    height: '12px',
    backgroundColor: '#D3DEE7',
    borderRadius: '4px',
    mb: '4px',
  },
  description: {
    textTransform: 'uppercase',
  },
  label: {
    fontSize: '12px',
    color: 'text.disabled',
    mb: '4px',
  },
}

export default style
