import { call, put, throttle, takeLatest, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import {
  addMemberApi,
  deleteMemberApi,
  getCompanyByIdApi,
  getCompanyByNameApi,
  saveCompanyApi,
  updateCompanyApi,
} from '../../api/companies'
import { SampleError } from '../../api/types'
import { setIsCompanyPanel, setIsMembersPanel } from '../Builder/actions'
import { selectCompanyType } from '../Builder/selectors'
import { setClientCompanyId, setProductCompanyId } from '../Callsheet/actions'
import { Contact } from '../Contacts/types'
import { setNotificationErrorWorker } from '../Notification/sagas'

import {
  saveCompanyRequestError,
  saveCompanyRequestSuccess,
  getCompaniesRequestError,
  getCompaniesRequestSuccess,
  getCompanyRequestSuccess,
  addCompanyMemberRequest,
  setCompany,
  updateCompanyMembersRequestSuccess,
  updateCompanyMembersRequestError,
  updateCompanyRequestSuccess,
  updateCompanyRequestError,
} from './actions'
import {
  SAVE_COMPANY_REQUEST,
  GET_COMPANIES_BY_NAME_REQUEST,
  GET_COMPANY_BY_ID_REQUEST,
  ADD_MEMBER_REQUEST,
  DELETE_MEMBER_REQUEST,
  UPDATE_COMPANY_MEMBERS_REQUEST,
  UPDATE_COMPANY_REQUEST,
} from './actionTypes'

import {
  AddMemberRequestType,
  SaveCompanyRequestType,
  GetCompaniesByNameRequestType,
  GetCompaniesByIdRequestType,
  DeleteMemberRequestType,
  UpdateCompanyMembersRequestType,
  UpdateCompanyRequestType,
  Company,
} from './types'
import {
  SaveCompanyResultType,
  GetCompaniesResultType,
  GetCompanyResultType,
} from '../../api/companies/types'

export function* AddMemberRequestWorker({
  payload: { companyId, contactId },
}: AddMemberRequestType) {
  try {
    yield call(addMemberApi, companyId, contactId)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* DeleteMemberRequestWorker({
  payload: { companyId, memberId },
}: DeleteMemberRequestType) {
  try {
    yield call(deleteMemberApi, companyId, memberId)
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
  }
}

export function* UpdateCompanyMembersRequestWorker({
  payload: { companyId, deletedMembers, addedMembers },
}: UpdateCompanyMembersRequestType) {
  try {
    if (deletedMembers.length) {
      for (let i = 0; i < deletedMembers.length; i += 1) {
        yield call(deleteMemberApi, companyId, deletedMembers[i].id)
      }
    }
    if (addedMembers.length) {
      for (let i = 0; i < addedMembers.length; i += 1) {
        yield call(addMemberApi, companyId, addedMembers[i].id)
      }
    }

    yield put(getCompaniesRequestSuccess([]))
    yield put(updateCompanyMembersRequestSuccess())
    yield put(setIsMembersPanel(false))
  } catch ({ response: { data } }) {
    yield updateCompanyMembersRequestError()
    yield setNotificationErrorWorker(data as SampleError)
  }
}

function* addCompanyCallbackSaga(
  primaryMembers: Contact[],
  companyData: Company,
  companyId: string
) {
  const companyType: string = yield select(selectCompanyType)

  yield put(
    setCompany(companyType, {
      ...companyData,
      ...(companyType === 'production' ? { primaryMembers } : {}),
    })
  )

  if (companyType === 'client') {
    yield put(setClientCompanyId(companyId))
  } else {
    yield put(setProductCompanyId(companyId))
  }

  yield put(setIsCompanyPanel(false))
}

export function* SaveCompanyRequestWorker({
  payload: {
    company: { primaryMembers = [], ...companyData },
  },
}: SaveCompanyRequestType) {
  const companyType: string = yield select(selectCompanyType)
  try {
    const {
      data: {
        data: { id },
      },
    }: AxiosResponse<SaveCompanyResultType> = yield call(
      saveCompanyApi,
      companyData
    )

    for (let i = 0; i < primaryMembers?.length; i += 1) {
      yield put(addCompanyMemberRequest(id, primaryMembers[i].id))
    }

    yield addCompanyCallbackSaga(primaryMembers, companyData, id)
    yield put(saveCompanyRequestSuccess(companyType, id))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(saveCompanyRequestError(data as SampleError))
  }
}

export function* UpdateCompanyRequestWorker({
  payload: {
    companyId,
    company: { primaryMembers = [], ...companyData },
  },
}: UpdateCompanyRequestType) {
  const companyType: string = yield select(selectCompanyType)
  try {
    yield call(updateCompanyApi, companyId, companyData)

    yield addCompanyCallbackSaga(primaryMembers, companyData, companyId)
    yield put(updateCompanyRequestSuccess(companyType, companyId))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(updateCompanyRequestError(data as SampleError))
  }
}

export function* GetCompaniesByNameRequestWorker({
  payload: { name, catalogOnly },
}: GetCompaniesByNameRequestType) {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetCompaniesResultType> = yield call(
      getCompanyByNameApi,
      name,
      catalogOnly
    )
    yield put(getCompaniesRequestSuccess(data))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getCompaniesRequestError(data as SampleError))
  }
}

export function* GetCompanyByIdRequestWorker({
  payload: { companyType, id },
}: GetCompaniesByIdRequestType) {
  try {
    const {
      data: { data },
    }: AxiosResponse<GetCompanyResultType> = yield call(getCompanyByIdApi, id)
    yield put(getCompanyRequestSuccess(data, companyType))
  } catch ({ response: { data } }) {
    yield setNotificationErrorWorker(data as SampleError)
    yield put(getCompaniesRequestError(data as SampleError))
  }
}

export function* requestCompaniesWatcher(): Generator {
  yield takeLatest(SAVE_COMPANY_REQUEST, SaveCompanyRequestWorker)
  yield takeLatest(UPDATE_COMPANY_REQUEST, UpdateCompanyRequestWorker)
  yield takeLatest(GET_COMPANY_BY_ID_REQUEST, GetCompanyByIdRequestWorker)
  yield throttle(
    500,
    GET_COMPANIES_BY_NAME_REQUEST,
    GetCompaniesByNameRequestWorker
  )
  yield takeLatest(ADD_MEMBER_REQUEST, AddMemberRequestWorker)
  yield takeLatest(DELETE_MEMBER_REQUEST, DeleteMemberRequestWorker)
  yield takeLatest(
    UPDATE_COMPANY_MEMBERS_REQUEST,
    UpdateCompanyMembersRequestWorker
  )
}
