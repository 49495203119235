import { createTheme } from '@mui/material/styles'
import {
  COLOR_TEXT_DISABLED,
  COLOR_TEXT_PRIMARY,
  COLOR_TEXT_SECONDARY,
  THEME_BORDER_RADIUS,
  THEME_FONT_FAMILY,
  COLOR_PRIMARY_MAIN,
  COLOR_PRIMARY_LIGHT,
  COLOR_INFO_MAIN,
  COLOR_ERROR_MAIN,
  COLOR_SUCCESS_MAIN,
  COLOR_SECONDARY_DARK,
  COLOR_SECONDARY_MAIN,
  COLOR_SECONDARY_LIGHT,
} from './constants'

const AppTheme = createTheme({
  typography: {
    fontFamily: THEME_FONT_FAMILY,
    button: {
      textTransform: 'none',
    },
    h2: {
      fontSize: '32px',
      fontWeight: 800,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 800,
    },
    h5: {
      fontSize: '16px',
      fontWeight: 900,
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 900,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    body2: {
      fontSize: '12px',
      fontWeight: 900,
      lineHeight: '20px',
    },
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY_MAIN,
      light: COLOR_PRIMARY_LIGHT,
    },
    secondary: {
      dark: COLOR_SECONDARY_DARK,
      main: COLOR_SECONDARY_MAIN,
      light: COLOR_SECONDARY_LIGHT,
    },
    info: {
      main: COLOR_INFO_MAIN,
    },
    error: {
      main: COLOR_ERROR_MAIN,
    },
    success: {
      main: COLOR_SUCCESS_MAIN,
    },
    text: {
      primary: COLOR_TEXT_PRIMARY,
      secondary: COLOR_TEXT_SECONDARY,
      disabled: COLOR_TEXT_DISABLED,
    },
    divider: '#E2E2E2',
  },
  shape: {
    borderRadius: THEME_BORDER_RADIUS,
  },
  spacing: [8, 16, 20, 24, 32, 40],
  components: {
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          height: '40px',
          borderRadius: '8px',
        },
        sizeSmall: {
          height: '28px',
          borderRadius: '4px',
        },
        sizeLarge: {
          height: '52px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: COLOR_TEXT_PRIMARY,
          fontSize: '16px',
          fontWeight: 900,
          textAlign: 'left',
          marginBottom: '8px',
          textTransform: 'capitalize',
        },
        asterisk: { color: COLOR_ERROR_MAIN },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          input: {
            fontSize: '14px',
            padding: '12px 14px',
            height: '20px',
          },
        },
        sizeSmall: {
          height: '36px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#E2E2E2',
        },
      },
    },
  },
})

export default AppTheme
