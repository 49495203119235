import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToggle } from '../../hooks/useToggle'
import CallsheetsFilterSection from '../../components/CallsheetsFilterSection'
import { RequestStatuses } from '../../api/constants'
import { GearSixIcon } from '../../assets/icons'
import AvatarBox from '../../components/AvatarBox'
import CallsheetCard from '../../components/CallsheetCard'
import CallsheetSearchField from '../../components/CallsheetSearchField'
import CreateCallsheetCard from '../../components/CreateCallsheetCard'
import CallsheetsList from '../../components/CallsheetsList'
import Loader from '../../components/Loader'
import OptionsButton from '../../components/OptionsButton'
import { getCallsheetsRequest } from '../../store/Callsheet/actions'
import {
  selectCallsheets,
  selectCallsheetsRequestStatus,
} from '../../store/Callsheet/selectors'
import { Callsheet } from '../../store/Callsheet/types'
import { clearStore } from '../../store/rootActions'
import { deleteTokens } from '../../utils/tokens'

import style from './style'

const CallsheetsPage = () => {
  const dispatch = useDispatch()
  const callsheets: Callsheet[] = useSelector(selectCallsheets)
  const requestStatus = useSelector(selectCallsheetsRequestStatus)
  const [isGalleryView, toggleView] = useToggle(true)

  useEffect(() => {
    dispatch(getCallsheetsRequest())
  }, [])

  const optionsMenuItems = [
    {
      title: 'Sign out',
      onClick: () => {
        deleteTokens()
        dispatch(clearStore())
      },
    },
  ]

  return (
    <Box>
      <Box sx={style.header}>
        <Box sx={style.titleBox}>
          <Typography sx={style.title}>Callsheets</Typography>
          <GearSixIcon />
        </Box>
        <Box sx={style.searchBox}>
          <CallsheetSearchField />
          <OptionsButton menuItems={optionsMenuItems}>
            <AvatarBox />
          </OptionsButton>
        </Box>
      </Box>
      <CallsheetsFilterSection
        isGalleryView={isGalleryView}
        onToggle={toggleView}
      />
      {requestStatus === RequestStatuses.UNCALLED ||
      requestStatus === RequestStatuses.PENDING ? (
        <Loader />
      ) : (
        <Box sx={style.gallery}>
          {isGalleryView ? (
            <>
              <CreateCallsheetCard />
              {callsheets.map(
                ({ id, title, eventDate, crewCallTime, status }) => (
                  <CallsheetCard
                    key={id}
                    id={id!}
                    title={title}
                    eventDate={eventDate}
                    crewCallTime={crewCallTime}
                    status={status}
                  />
                )
              )}
            </>
          ) : (
            <CallsheetsList callsheets={callsheets} />
          )}
        </Box>
      )}
    </Box>
  )
}

export default CallsheetsPage
