import { createSelector } from 'reselect'
import { selectCompanyType } from '../Builder/selectors'
import { AppState } from '../types'

export const selectSaveCompanyRequestStatus = (state: AppState) =>
  state.companiesState.saveCompanyRequestStatus
export const selectGetCompaniesRequestStatus = (state: AppState) =>
  state.companiesState.getCompaniesRequestStatus
export const selectMembersRequestStatus = (state: AppState) =>
  state.companiesState.membersRequestStatus
export const selectCompanies = (state: AppState) =>
  state.companiesState.companies
export const selectCompaniesState = (state: AppState) => state.companiesState
export const selectCurrentCompany = createSelector(
  [selectCompanyType, selectCompaniesState],
  (type, companies) => companies[type]
)
export const selectCompanyPrimaryMembers = (state: AppState) =>
  state.companiesState.production.primaryMembers
