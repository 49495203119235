import React, { FC } from 'react'
import { Box } from '@mui/material'
import { CameraIcon } from '../../assets/icons'

import style from './style'

const AvatarBox: FC<{ img?: string }> = ({ img }) => {
  return (
    <Box sx={style.avatarBox}>
      {img ? (
        <img src={img} alt='avatar' />
      ) : (
        <Box sx={style.placeholder}>
          <CameraIcon />
        </Box>
      )}
    </Box>
  )
}

export default AvatarBox
