import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

import style from './style'

const CallsheetCompletedFooter: FC<{ id: string }> = ({ id }) => {
  return (
    <Box sx={style.content}>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>Attended:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.attended}>5/6</Box>
        </Box>
      </Box>
      <Box sx={style.statisticsItem}>
        <Typography sx={style.title}>views:</Typography>
        <Box sx={style.numBox}>
          <Box sx={style.views}>74</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CallsheetCompletedFooter
