import { AxiosPromise } from 'axios'
import { Callsheet } from '../../store/Callsheet/types'
import { apiClient } from '../clients'
import { METHODS, URLS } from '../constants'

export const getCallsheets = (): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.CALLSHEET,
  })

export const getCallsheetByIdApi = (id: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: `${URLS.CALLSHEET}/${id}`,
  })

export const saveCallsheetApi = (callsheet: Callsheet): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: URLS.CALLSHEET,
    data: callsheet,
  })

export const updateCallsheetApi = (
  callsheetId: string,
  callsheet: Callsheet
): AxiosPromise =>
  apiClient.request({
    method: METHODS.PATCH,
    url: `${URLS.CALLSHEET}/${callsheetId}`,
    data: callsheet,
  })

export const getMemberTitlesApi = (): AxiosPromise =>
  apiClient.request({
    method: METHODS.GET,
    url: URLS.MEMBER_TITLES,
  })

export const publishCallsheetApi = (callsheetId: string): AxiosPromise =>
  apiClient.request({
    method: METHODS.POST,
    url: `${URLS.CALLSHEET}/${callsheetId}/publish`,
  })
