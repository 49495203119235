import { useDispatch, useSelector } from 'react-redux'
import React, { useState, FC, useMemo, useEffect } from 'react'
import { Stack, Typography, Box } from '@mui/material'

import { RequestStatuses } from '../../../../../api/constants'
import { setIsAddContactPanel } from '../../../../../store/Builder/actions'
import { selectIsAddContactPanel } from '../../../../../store/Builder/selectors'
import { selectCompanyPrimaryMembers } from '../../../../../store/Companies/selectors'

import {
  selectContacts,
  selectContactsRequestStatus,
} from '../../../../../store/Contacts/selectors'
import { Contact } from '../../../../../store/Contacts/types'
import { getContactsRequest } from '../../../../../store/Contacts/actions'

import Loader from '../../../../../components/Loader'
import SidePanel from '../../../../../components/SidePanel'
import AddContactForm from '../../../ProjectOverview/MembersForm/AddContactForm/AddContactForm'
import SearchBox from '../../../ProjectOverview/MembersForm/SearchBox'
import CustomTabs from '../../../../../components/CustomTabs'
import GroupContactItem from '../GroupContactItem'
import GroupsFilter from '../../../ProjectOverview/GroupsFilter'

import {
  groupsFilter,
  nameFilter,
  typeFilter,
} from '../../../../../utils/contactHelpers'

import style from './style'

const TABS_LABELS = ['Contacts']

const DEFAULT_TAB = 'contacts'

const GroupMembersBox: FC = () => {
  const dispatch = useDispatch()
  const contacts = useSelector(selectContacts)
  const requestStatus = useSelector(selectContactsRequestStatus)
  const isAddContactPanel = useSelector(selectIsAddContactPanel)
  const primaryMembers = useSelector(selectCompanyPrimaryMembers)

  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [tabName, setTabName] = useState(DEFAULT_TAB)
  const [searchName, setSearchName] = useState('')
  const [primaryContacts, setPrimaryContacts] = useState<Contact[]>([])

  const isSearched = !!searchName

  const handleTabClick = (name: string) => () => {
    setTabName(name.toLowerCase())
  }

  const handleAddContactClose = () => {
    dispatch(setIsAddContactPanel(false))
  }

  useEffect(() => {
    if (primaryMembers) {
      setPrimaryContacts(primaryMembers)
    }
  }, [primaryMembers])

  useEffect(() => {
    dispatch(getContactsRequest())
  }, [])

  const SearchResult = useMemo(() => {
    return (
      <>
        <Typography variant='h5' component='h5'>
          Search result
        </Typography>
        <Box sx={style.contactsContainer}>
          {nameFilter(searchName)(contacts).map(contact => (
            <GroupContactItem contact={contact} key={contact.email} />
          ))}
        </Box>
      </>
    )
  }, [searchName])

  return (
    <Box>
      <Stack gap={2}>
        <SearchBox onChange={setSearchName} />
        {isSearched ? (
          SearchResult
        ) : (
          <>
            <CustomTabs onTabClick={handleTabClick} tabLabels={TABS_LABELS} />
            <Stack sx={style.contactsContainer}>
              {requestStatus === RequestStatuses.PENDING ? (
                <Loader />
              ) : (
                <>
                  <Typography variant='h5' component='h5'>
                    Queue
                  </Typography>
                  <Box>
                    {primaryContacts.map(contact => (
                      <GroupContactItem
                        contact={contact}
                        key={contact.email}
                        isPrimary
                      />
                    ))}
                  </Box>
                  <Box sx={style.filterSection}>
                    <Typography variant='h5' component='h5'>
                      All Contacts
                    </Typography>
                    <GroupsFilter
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                    />
                  </Box>
                  <Box>
                    {typeFilter(tabName)(
                      groupsFilter(selectedTags)(contacts)
                    ).map(contact => (
                      <GroupContactItem contact={contact} key={contact.email} />
                    ))}
                  </Box>
                </>
              )}
            </Stack>
          </>
        )}
      </Stack>
      <SidePanel isOpen={isAddContactPanel} onClose={handleAddContactClose}>
        <AddContactForm />
      </SidePanel>
    </Box>
  )
}

export default GroupMembersBox
