import React, { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { TextField, Divider, Box, Grid, InputLabel } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import { setIsAddContactPanel } from '../../../../../store/Builder/actions'
import { getMemberTitlesRequest } from '../../../../../store/Callsheet/actions'
import { selectMembersTitles } from '../../../../../store/Callsheet/selectors'
import {
  addContactRequest,
  getContactsRequest,
} from '../../../../../store/Contacts/actions'
import { selectContactsRequestStatus } from '../../../../../store/Contacts/selectors'
import { Contact } from '../../../../../store/Contacts/types'
import { stripPhoneNumber } from '../../../../../utils/contactHelpers'
import { removeEmptyFields } from '../../../../../utils/filters'
import ContactLogoUploader from '../../../../../components/ContactLogoUploader/ContactLogoUploader'
import ControlButtons from '../../../../../components/ControlButtons'
import CustomScroll from '../../../../../components/CustomScroll/CustomScroll'
import CustomTextField from '../../../../../components/CustomTextField'
import TextHead from '../../../../../components/HeadText/HeadText'
import PhoneInput from '../../../../../components/PhoneInput/PhoneInput'
import SearchField from '../../../../../components/SearchField'
import {
  WebsiteIcon,
  InstagramBlueIcon,
  UnsplashIcon,
} from '../../../../../assets/icons'
import { initialContact } from './constants'
import style from './style'

const AddContactForm: FC = () => {
  const titles = useSelector(selectMembersTitles)
  const requestStatus = useSelector(selectContactsRequestStatus)
  const [memberTitles, setMemberTitles] = useState(titles ?? [])
  const [contact, setContact] = useState<Omit<Contact, 'id'>>(initialContact)
  const [errors, setErrors] = useState<Record<string, boolean>>({})
  const dispatch = useDispatch()

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrors(prev => ({
      ...prev,
      [event.target.name]: false,
    }))
    setContact(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }

  const handleLogoChange = (logo: string) => {
    setContact(prev => ({
      ...prev,
      logo,
    }))
  }

  const handlePhoneChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = ({ target: { value } }) => {
    setErrors(prev => ({
      ...prev,
      phone: false,
    }))
    setContact(prev => ({
      ...prev,
      phone: stripPhoneNumber(value),
    }))
  }

  const handleTitleSearch: ChangeEventHandler<HTMLTextAreaElement> = async ({
    target: { value },
  }) => {
    setContact(prev => ({
      ...prev,
      title: value,
    }))
    if (value.length > 2) {
      const filtered = titles.filter((title: string) => title.includes(value))
      setMemberTitles(filtered)
    }
  }

  const validateInputs = () => {
    const err: Record<string, boolean> = {}
    const { isEmail, isMobilePhone, isURL } = validator

    err.firstName = !contact.firstName
    err.lastName = !contact.lastName
    err.email = !contact.email || !isEmail(contact.email)
    err.phone = !!(contact.phone && !isMobilePhone(contact.phone))
    err.website = !!(contact.website && !isURL(contact.website))
    err.unsplashLink = !!(contact.unsplashLink && !isURL(contact.unsplashLink))
    err.instagramLink = !!(
      contact.instagramLink && !isURL(contact.instagramLink)
    )

    setErrors(err)
    return Object.values(err).some(val => val)
  }

  const handleCancel = () => {
    dispatch(setIsAddContactPanel(false))
  }

  const handleConfirm = () => {
    const hasErrors = validateInputs()
    if (!hasErrors) {
      const filteredContact = removeEmptyFields(contact)
      dispatch(addContactRequest(filteredContact as Contact))
      dispatch(getContactsRequest())
    }
  }

  // useEffect(() => {
  //   if (!titles.length) {
  //     dispatch(getMemberTitlesRequest())
  //   }
  // }, [])

  return (
    <Box sx={style.wrapper}>
      <TextHead
        title='Add new contact'
        subtitle='Please fill in the information below.'
      />
      <CustomScroll height={700}>
        <Box sx={style.content}>
          <InputLabel>Basic Info</InputLabel>
          <ContactLogoUploader onChange={handleLogoChange} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                id='firstName'
                name='firstName'
                label='First Name'
                labelStyles={{ ...style.labelStyles, mt: 0 }}
                placeholder='Wade...'
                value={contact.firstName}
                onInputChange={handleInputChange}
                required
                error={errors.firstName}
                helperText={errors.firstName ? 'Field empty' : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                id='lastName'
                name='lastName'
                label='Last Name'
                labelStyles={{ ...style.labelStyles, mt: 0 }}
                placeholder='Warren...'
                value={contact.lastName}
                onInputChange={handleInputChange}
                required
                error={errors.lastName}
                helperText={errors.lastName ? 'Field empty' : ''}
              />
            </Grid>
          </Grid>
          <CustomTextField
            id='middleName'
            name='middleName'
            label='Middle Name (Optional)'
            placeholder='Kyle...'
            value={contact.middleName}
            onInputChange={handleInputChange}
            labelStyles={style.labelStyles}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                id='email'
                name='email'
                label='Email'
                labelStyles={style.labelStyles}
                placeholder='wade.warren@example.com...'
                value={contact.email}
                onInputChange={handleInputChange}
                required
                error={errors.email}
                helperText={
                  errors.email
                    ? 'Incorrect email address. Please try again'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel htmlFor='phone' sx={{ ...style.labelStyles, mt: 3 }}>
                Phone number
              </InputLabel>
              <PhoneInput
                error={errors.phone}
                value={contact.phone}
                helperText='Incorrect phone number. Please try again.'
                onChange={handlePhoneChange}
              />
            </Grid>
          </Grid>
          <SearchField
            id='title'
            value={contact.title}
            onInputChange={handleTitleSearch}
            placeholder='Select or type an option...'
            label='Primary Role (Optional)'
            labelStyles={{ ...style.labelStyles, mt: 3 }}
            options={memberTitles}
            requestStatus='uncalled'
          />
          <Divider sx={{ mt: 4, mb: 4 }} />
          <InputLabel>Links</InputLabel>
          <CustomTextField
            id='website'
            name='website'
            label='Web Link'
            labelStyles={style.labelStyles}
            LabelIcon={WebsiteIcon}
            placeholder='Add link here...'
            value={contact.website}
            onInputChange={handleInputChange}
            error={errors.website}
            helperText={
              errors.website ? 'Incorrect web address. Please try again.' : ''
            }
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                id='instagramLink'
                name='instagramLink'
                label='Instagram'
                labelStyles={style.labelStyles}
                LabelIcon={InstagramBlueIcon}
                placeholder='Add link here...'
                value={contact.instagramLink}
                onInputChange={handleInputChange}
                error={errors.instagramLink}
                helperText={
                  errors.instagramLink
                    ? 'Incorrect web address. Please try again.'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                id='unsplashLink'
                name='unsplashLink'
                label='Unsplash'
                labelStyles={style.labelStyles}
                LabelIcon={UnsplashIcon}
                placeholder='Add link here...'
                value={contact.unsplashLink}
                onInputChange={handleInputChange}
                error={errors.unsplashLink}
                helperText={
                  errors.unsplashLink
                    ? 'Incorrect web address. Please try again.'
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 4, mb: 4 }} />
          <InputLabel>Extras</InputLabel>
          <InputLabel sx={{ ...style.labelStyles, mt: 1 }}>Notes</InputLabel>
          <TextField
            placeholder='Type here...'
            name='notes'
            value={contact.notes}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={4}
            sx={{ mb: 4 }}
          />
        </Box>
      </CustomScroll>
      <Box sx={style.footer}>
        <ControlButtons
          confirmTitle='Add new contact'
          cancelTitle='Back to all'
          handleCancelBtn={handleCancel}
          handleConfirmBtn={handleConfirm}
          requestStatus={requestStatus}
        />
      </Box>
    </Box>
  )
}

export default AddContactForm
