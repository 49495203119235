import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import FileUploadField from '../FileUploadField'

import style from './style'

const Attachments: FC = () => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant='h5' sx={style.label}>
        Attachments
      </Typography>
      <FileUploadField onChange={() => {}} defaultValue='' />
    </Box>
  )
}

export default Attachments
