import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import FinalTouchesForm from './FinalTouchesForm'

import { selectCallsheetId } from '../../../store/Callsheet/selectors'
import { getCallsheetRequest } from '../../../store/Callsheet/actions'

const FinalTouches: FC = () => {
  const callsheetId = useSelector(selectCallsheetId)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCallsheetRequest(callsheetId))
  }, [])
  return (
    <Box>
      <FinalTouchesForm />
    </Box>
  )
}

export default FinalTouches
