import React, { FC, useEffect, useState } from 'react'
import { noop } from 'lodash'
import { Box, ButtonBase, Typography } from '@mui/material'
import ImageUploading, { ImageListType } from 'react-images-uploading'

import {
  ContactUploadAreaIcon,
  EditIcon,
  TrashcanIcon,
} from '../../assets/icons'
import { MAX_FILE_SIZE } from '../../constants'
import { prepareImageValue, stripBase64Data } from '../../utils/image'
import CustomTextButton from '../CustomTextButton'

import style from './style'

type ImageUploadFieldTypes = {
  onChange: typeof noop
  defaultValue?: string
}

const ContactLogoUploader: FC<ImageUploadFieldTypes> = ({
  onChange,
  defaultValue,
}) => {
  const [image, setImage] = useState(prepareImageValue(defaultValue))

  useEffect(() => {
    setImage(prepareImageValue(defaultValue))
  }, [defaultValue])

  const handleChange = (imageList: ImageListType) => {
    setImage(imageList as never[])
    onChange(imageList[0] && stripBase64Data(imageList[0].dataURL))
  }

  return (
    <ImageUploading
      value={image}
      onChange={handleChange}
      maxFileSize={MAX_FILE_SIZE}
    >
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
        onImageRemove,
        dragProps,
        errors,
      }) => (
        <Box sx={{ mt: 1 }}>
          <Box
            className='upload__image-wrapper'
            sx={style.wrapper}
            {...dragProps}
          >
            {imageList.map(currentImage => (
              <Box
                key={currentImage.dataURL}
                className='image-item'
                display='flex'
                alignItems='center'
              >
                <Box sx={{ marginRight: '20px', textAlign: 'center' }}>
                  <img
                    src={currentImage.dataURL}
                    style={{
                      maxHeight: '96px',
                      maxWidth: '96px',
                      borderRadius: '8px',
                    }}
                    alt='contact logo'
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-between'
                  alignItems='flex-start'
                >
                  {image.length ? (
                    <Box
                      display='flex'
                      alignItems='center'
                      flexDirection='column'
                    >
                      <CustomTextButton
                        title='Update image'
                        Icon={EditIcon}
                        onClick={() => onImageUpdate(0)}
                      />
                      <Box sx={{ mb: 2 }} />
                      <CustomTextButton
                        title='Delete image'
                        Icon={TrashcanIcon}
                        onClick={() => onImageRemove(0)}
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            ))}
            {!image.length && (
              <Box display='flex' alignItems='center'>
                <Box sx={{ mr: 1, cursor: 'pointer' }} onClick={onImageUpload}>
                  <ContactUploadAreaIcon />
                </Box>
                <Box>
                  <ButtonBase onClick={onImageUpload} sx={{ mb: '4px' }}>
                    <Typography
                      variant='subtitle2'
                      fontWeight={400}
                      color='text.secondary'
                    >
                      Upload contact image
                    </Typography>
                  </ButtonBase>
                  {errors && (
                    <Box>
                      {errors.acceptType && (
                        <span>Your selected file type is not allow</span>
                      )}
                      {errors.maxFileSize && (
                        <>
                          <Typography variant='body1' color='text.disabled'>
                            Max file size: 1MB
                          </Typography>
                          <Typography
                            variant='body1'
                            color='error.main'
                            fontSize={10}
                          >
                            Uploaded image size too large. Try again.
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </ImageUploading>
  )
}

export default ContactLogoUploader
